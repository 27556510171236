import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  brokers_list: {
    loading: true,
    brokers: [],
    error: null,
  },
  create_broker_section_e: {
    loading: false,
  },
  edit_broker_section_e: {
    loading: true,
    updating: false,
    error: null,
    broker: null,
    uploading_document: false,
    documents: [],
  },
  broker_section_e_quotations: {
    loading: true,
    error: null,
    broker: null,
    quotations: [],
    purchased_quotations: [],
    total_pages: 1,
    page_number: 1,
  },
  broker_section_e_reports: {
    loading: true,
    error: null,
    brokers: [],
    data: {
      sum_of_contracts_in_emessa: 0,
      nr_of_contract_in_emessa: 0,
      nr_of_insurance_requests: 0,
      commission_gained: 0,
      insurance_requests_chart_data: [],
      commission_earned_chart_data: {
        brokers: [],
        you: [],
      },
    },
  },

};

export const brokersSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    listBrokersPending: (state) => {
      state.brokers_list.loading = true;
      state.brokers_list.error = null;
    },
    listBrokersRejected: (state, { payload }) => {
      state.brokers_list.loading = false;
      state.brokers_list.error = payload;
    },
    listBrokersSuccess: (state, { payload }) => {
      state.brokers_list.loading = false;
      state.brokers_list.brokers = payload;
    },

    createBrokerSectionEPending: (state) => {
      state.create_broker_section_e.loading = true;
    },
    createBrokerSectionEFulfilled: (state) => {
      state.create_broker_section_e.loading = false;
    },

    getBrokerSectionEPending: (state) => {
      state.edit_broker_section_e.loading = true;
      state.edit_broker_section_e.error = null;
    },
    getBrokerSectionERejected: (state, { payload }) => {
      state.edit_broker_section_e.loading = false;
      state.edit_broker_section_e.error = payload;
    },
    getBrokerSectionEFulfilled: (state, { payload }) => {
      state.edit_broker_section_e.loading = false;
      state.edit_broker_section_e.broker = payload.broker;
      state.edit_broker_section_e.documents = payload.documents;
    },
    getBrokerSectionEReset: (state) => {
      state.edit_broker_section_e = { ...initialState.edit_broker_section_e };
    },

    updatingBrokerSectionEPending: (state) => {
      state.edit_broker_section_e.updating = true;
    },
    updatingBrokerSectionEFulfilled: (state) => {
      state.edit_broker_section_e.updating = false;
    },

    uploadingDocumentForBrokerSectionEPending: (state) => {
      state.edit_broker_section_e.uploading_document = true;
    },
    uploadingDocumentForBrokerSectionEFulfilled: (state) => {
      state.edit_broker_section_e.uploading_document = false;
    },

    getBrokerSectionEDocumentsSuccess: (state, { payload }) => {
      state.edit_broker_section_e.documents = payload;
    },

    getBrokerSectionEQuotationsPending: (state) => {
      state.broker_section_e_quotations.loading = true;
      state.broker_section_e_quotations.error = null;
    },
    getBrokerSectionEQuotationsRejected: (state, { payload }) => {
      state.broker_section_e_quotations.loading = false;
      state.broker_section_e_quotations.error = payload;
    },
    getBrokerSectionEQuotationsFulfilled: (state, { payload }) => {
      state.broker_section_e_quotations.loading = false;
      state.broker_section_e_quotations.quotations = payload.data.quotations;
      state.broker_section_e_quotations.broker = payload.data.broker;
      state.broker_section_e_quotations.page_number = payload.data.current_page;
      state.broker_section_e_quotations.total_pages = payload.data.total_pages;
    },
    getBrokerSectionEQuotationsReset: (state) => {
      state.broker_section_e_quotations = {
        ...initialState.broker_section_e_quotations,
      };
    },
    getBrokerSectionEPurchasedQuotationsPending: (state) => {
      state.broker_section_e_quotations.loading = true;
      state.broker_section_e_quotations.error = null;
    },
    getBrokerSectionEPurchasedQuotationsRejected: (state, { payload }) => {
      state.broker_section_e_quotations.loading = false;
      state.broker_section_e_quotations.error = payload;
    },
    getBrokerSectionEPurchasedQuotationsFulfilled: (state, { payload }) => {
      state.broker_section_e_quotations.loading = false;
      state.broker_section_e_quotations.purchased_quotations = payload.data.quotations;
      state.broker_section_e_quotations.broker = payload.data.broker;
      state.broker_section_e_quotations.page_number = payload.data.current_page;
      state.broker_section_e_quotations.total_pages = payload.data.total_pages;
    },
    getBrokersSectionEForReports: (state, { payload }) => {
      state.broker_section_e_reports.brokers = payload;
      state.broker_section_e_reports.loading = true;
    },
    getBrokersSectionEForReportsRejected: (state, { payload }) => {
      state.broker_section_e_reports.error = payload;
      state.broker_section_e_reports.loading = false;
    },
    getBrokersSectionEForReportsPending: (state) => {
      state.broker_section_e_reports.error = null;
      state.broker_section_e_reports.loading = true;
    },
    getBrokersSectionEDataPending: (state) => {
      state.broker_section_e_reports.loading = true;
      state.broker_section_e_reports.error = null;
    },
    getBrokersSectionEDataRejected: (state, { payload }) => {
      state.broker_section_e_reports.loading = false;
      state.broker_section_e_reports.error = payload;
    },
    getBrokersSectionEDataFulfilled: (state, { payload }) => {
      state.broker_section_e_reports.loading = false;
      state.broker_section_e_reports.data = payload;
    },
    getBrokersSectionEReportsReset: (state) => {
      state.broker_section_e_reports = {
        ...initialState.broker_section_e_reports,
      };
    },

  },
});

export default callerCreator(brokersSlice.actions);
