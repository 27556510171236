import React, { useEffect } from 'react';
import { guideType } from '../../constants';
import {
  QuoteInformationContainer,
  QuoteInformationItem,
} from '../QuotationCard.styles';

function QuoteInfoComponent({
  quote,
  requestInfo,
  profession,
  type,
  setExtraText,
}) {
  useEffect(() => {
    let defaultText = '';

    if (quote.company_id != 15) {
      defaultText += `Frequenza pagamento: ${
        quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'
      } `;
    }
    if (requestInfo?.guide_type) {
      defaultText += `- Guida: ${
        guideType.find((v) => v.id === requestInfo.guide_type).name
      } `;
    }
    if (quote.company_id === 1) {
      defaultText
        += "- Emissione: Tradizionale -  Risarcimento: Rc con Risarcimento Diretto e RFS - Massimale: 6'450'000€  Danni a persone, 1'050'000€ Danni a cose";
    }

    if (
      quote.company_id === 9
      || quote.company_id === 10
      || quote.company_id === 17
      || quote.added_manually === 1
      || (quote.added_manually === 0 && quote.company_id === null)
    ) {
      defaultText
        += "- Emissione: Tradizionale -  Risarcimento: Rc con Risarcimento Diretto e RFS - Massimale: 6'450'000€  Danni a persone, 1'050'000€ Danni a cose";
    }

    if (quote.company_id === 2) {
      defaultText
        += "- Emissione: Diretta -  Risarcimento: Rc con Risarcimento Diretto e RFS - Massimale: 6'450'000€  Danni a persone, 1'050'000€ Danni a cose";
    }

    if (
      quote.company_id === 11
      || quote.company_id === 12
      || quote.company_id === 13
      || quote.company_id === 14
    ) {
      defaultText
        += '- Emissione: Tradizionale -  Risarcimento: Rc con Risarcimento Diretto e RFS';
      if (quote.massimale != 0) {
        defaultText += `- Massimale: ${quote.massimale}`;
      }
      if (quote.isotermici) {
        defaultText += `- Isotermici: ${quote.isotermici}`;
      }
    }

    if (quote.company_id === 16) {
      defaultText += '- Emissione: Tradizionale ';
      if (quote.massimale != 0) {
        defaultText += `- Massimale: ${new Intl.NumberFormat().format(
          quote.massimale,
        )}`;
      }
    }

    if (quote.company_id === 4) {
      defaultText += `Professione: ${
        profession.profession_desc
      } - Massimale: ${Number(profession.billed_maximum).toLocaleString(
        'it-IT',
        {
          minimumFractionDigits: 2,
        },
      )} - Franchigia: 1,000€ - Retroattività: ${
        profession.retroactivity_name
      } - Scaglione fatturato: ${
        profession.billed
      } - Tempo medio di emissione: 2 giorni`;
    }

    if (quote.company_id === 8 && quote.product_id == 11) {
      if (quote.risk_massimale) {
        defaultText += `- Massimale: ${Number(
          quote.risk_massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.'),
        ).toLocaleString('it-IT', { minimumFractionDigits: 2 })} €`;
      }
      if (quote.massimale) {
        defaultText += `- Massimale: ${Number(
          quote.massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.'),
        ).toLocaleString('it-IT', { minimumFractionDigits: 2 })} €`;
      }
    }
    if (quote.company_id === 8 && quote.product_id == 13) {
      defaultText += '- Emissione: Tradizionale ';
      if (quote.massimale != 0 && quote.massimale !== null) {
        defaultText += `- Massimale: ${new Intl.NumberFormat().format(
          quote.massimale,
        )} €`;
      }
      if (quote.quote_type === 'collisione') {
        defaultText += '- Franchigia: 200 €';
      }
    }

    if (quote.company_id === 15) {
      defaultText += '- Emissione: Tradizionale - Frequenza pagamento: Annuo';
      if (quote.massimale != 0) {
        defaultText += `- Massimale: ${parseFloat(quote.massimale).toFixed(
          2,
        )} €`;
      }
    }

    if (quote.company_id === 8 && quote.product_id == 12) {
      if (quote.risk_massimale) {
        defaultText += `- Massimale: ${Number(
          quote.risk_massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.'),
        ).toLocaleString('it-IT', { minimumFractionDigits: 2 })} €`;
      }
      if (quote.massimale) {
        defaultText += `- Massimale: ${Number(
          quote.massimale.replace(/[,.](?=\d{3})/g, '').replace(',', '.'),
        ).toLocaleString('it-IT', { minimumFractionDigits: 2 })} €`;
      }
    }

    if (quote.product_name) {
      defaultText += `- Nome Prodotto: ${quote.product_name}`;
    }

    setExtraText(defaultText);
  }, [quote]);

  return (
    <>
      {quote.company_id === 1 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>Adriatic Assicurazioni</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {(quote.company_id === 9
        || quote.company_id === 10
        || quote.company_id === 17
        || quote.added_manually === 1
        || (quote.added_manually === 0 && quote.company_id === null)) && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>
                {quote.company
                  ? quote.company.name
                  : quote.preventivas_company.company_name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 2 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>Ipitq ma</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Diretta</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {(quote.company_id === 11
        || quote.company_id === 12
        || quote.company_id === 13
        || quote.company_id === 14) && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            {quote.massimale != 0 && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>{quote.massimale}</p>
              </QuoteInformationItem>
            )}
            <QuoteInformationItem>
              <h6>Isotermici:</h6>
              <p>{quote.isotermici}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 16 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>Global Assistance</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
            {quote.massimale != 0 && quote.massimale !== null && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {new Intl.NumberFormat().format(quote.massimale)}
                  €
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 4 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>RC Professionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Professione:</h6>
              <p>{profession.profession_desc}</p>
            </QuoteInformationItem>
            {profession.billed_maximum != 0 && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(profession.billed_maximum).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
          <QuoteInformationContainer className="ms-3 ms-sm-0">
            <QuoteInformationItem>
              <h6>Franchigia:</h6>
              <p>1,000&euro;</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Retroattività:</h6>
              <p>{profession.retroactivity_name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Scaglione fatturato:</h6>
              <p>
                {profession.billed}
                &euro;
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Tempo medio di emissione:</h6>
              <p>2 giorni</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 8 && quote.product_id == 11 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{requestInfo.product.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
            {quote.risk_massimale && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.risk_massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
            {quote.massimale && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 8 && quote.product_id == 13 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>{quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
            {quote.massimale != 0 && quote.massimale !== null && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {new Intl.NumberFormat().format(quote.massimale)}
                  €
                </p>
              </QuoteInformationItem>
            )}
            {quote.quote_type === 'collisione' && (
              <QuoteInformationItem>
                <h6>Franchigia</h6>
                <p>200 €</p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 15 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.sub_product ? 'Tutela Legale' : type.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Tradizionale</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>Annuo</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            {quote.massimale != 0 && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {parseFloat(quote.massimale).toFixed(2)}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
            <QuoteInformationItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </>
      )}
      {quote.company_id === 8 && quote.product_id == 12 && (
        <>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{quote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{requestInfo.product.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Nome Prodotto:</h6>
              <p>{quote.product_name}</p>
            </QuoteInformationItem>
            {quote.risk_massimale && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.risk_massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
            {quote.massimale && (
              <QuoteInformationItem>
                <h6>Massimale:</h6>
                <p>
                  {Number(
                    quote.massimale
                      .replace(/[,.](?=\d{3})/g, '')
                      .replace(',', '.'),
                  ).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationItem>
            )}
          </QuoteInformationContainer>
        </>
      )}
    </>
  );
}

export default QuoteInfoComponent;
