import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import Chart from 'react-apexcharts';
import PageLoading from '../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../config';
import {
  ConsulenzaReportButton,
  FailedQuoteTitle,
  FailedQuotesContainer,
  RcQuotesStyles,
} from './RcQuotes.styles';
import {
  addQuoteAutoToCart,
  addVehicleAmount,
  fetchAutoReport,
  loadAutoQuotes,
  removeQuoteAutoFromCart,
  resetAutoQuotes,
  updateAutoQuotesData,
  updateSortByAuto,
} from '../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert, generalAlertError } from '../common/utils/alerts';
import VehicleInfoCard from '../common/components/redesignComponents/Cards/VehicleInfoCard';
import { EstimateContainer } from '../common/components/redesignComponents/common/Redesign.styles';
import GuaranteesModal from '../common/components/redesignComponents/Modal/GuaranteesModal';
// import { SellingDistanceModal } from '../common/components/elements/SellingDistanceModal/SellingDistanceModal';
// import { SellingDistanceLink } from '../common/components/elements/SellingDistanceLink';
import { coveragesUtil } from '../utils/coverages';
import ErrorComponent from '../common/components/ErrorComponent';
// import { useTourContext } from '../context/useTourContext';
// import { handleCallbackRcQuotes } from '../common/utils/joyrideCallbacks';
// import { rcQuotesSteps } from '../common/utils/joyrideSteps';
import {
  RiskCard,
  RiskContainer,
} from '../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import { GuaranteesDefaultStateStyles } from '../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { primaQuoteTypes, quotationTypes } from '../common/constants';
import ModalQuotes from '../common/components/redesignComponents/ResultPageComponents/ModalQuotes';
import ButtonComponentLogic from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogic';
import SelectedQuotesCards from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCards';
import ButtonComponentLogicPrima from '../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicPrima';
import SelectedQuotesCardsPrima from '../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsPrima';
import ModalQuotesPrima from '../common/components/redesignComponents/ResultPageComponents/ModalQuotesPrima';
import {
  riskCardPrimaStyles,
  riskCardStyles,
} from '../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import { FormInputText } from '../common/formInputs/FormInputText';

function RcQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const [showGuaranteesModal, setShowGuaranteesModal] = useState(false);
  const [showConsulenzaReport, setShowConsulenzaReport] = useState(false);
  const [vehicleAmount, setVehicleAmount] = useState({
    furto_incendio: '',
    eventi_naturali: '',
    eventi_socio_politici: '',
  });

  const { updateMarkupLoading } = useSelector(
    (store) => store.insuranceRequests.infodrive_quotes,
  );

  const clearQuotesIntervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const [intervalQuotes, setIntervalQuotes] = useState(null);
  const state = useSelector((store) => store.insuranceRequests.auto_quotes);
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    preventivas_quotes,
    sortBy,
    error,
    report,
    loadingReport,
    quotations,
    addingQuote,
    processingQuote,
    riskQuotations,
  } = state;
  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  // const [showSellingDistanceModal, setShowSellingDistanceModal] = useState(false);
  const [counter, setCounter] = useState(180);
  const [currentQuotes, setCurrentQuotes] = useState('diretta');
  // const [orderedQuotes, setOrderedQuotes] = useState([]);
  // ? diretta = Greenia Companies
  // ? indiretta = Preventivass Companies
  // ? quotazioni = All Preventivass Quotes

  // const {
  //   setState,
  //   state: { run, showBeacon, steps },
  // } = useTourContext();

  const handleCurrentQuotesDisplay = (val) => {
    setCurrentQuotes(val);
  };

  const guaranteesPrima = (quote) => {
    const guarantees = quote.quotation_data_prima_guarantees
      .map((guarantee) => guarantee.slug)
      .filter((g) => g != 'rca' && g != 'furto_incendio');
    if (
      quote.quotation_data_prima_guarantees.find(
        (qg) => qg.slug == 'furto_incendio',
      ) != undefined
    ) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  // const getSellingDistanceLink = () => {
  //   const vehicleByProduct = {
  //     1: 'auto',
  //     3: 'autocarro',
  //   };

  //   return `${SELLING_DISTANCE_URL}/request/${requestInfo.distance_token}/${vehicleByProduct[requestInfo.product_id]}/results`;
  // };

  // const copySellingDistanceLink = async () => {
  //   const link = getSellingDistanceLink();

  //   await navigator.clipboard.writeText(link);
  //   generalAlertSuccess('Il collegamento e stato copiato');
  // };

  // useEffect(() => {
  //   rcQuotesSteps(quotes, requestInfo, setState);
  // }, [quotes, requestInfo]);

  // useEffect(() => {
  //   if (localStorage.getItem('quoteNewResult') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('quoteNewResult')]);

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('quoteNewResult');
  //   setState({ run: true });
  // };

  const refresh = () => loadAutoQuotes(requestToken);

  useEffect(() => {
    loadAutoQuotes(requestToken);

    return () => {
      resetAutoQuotes();
    };
  }, []);

  const clearQuotesInterval = () => {
    if (intervalQuotes !== null) {
      clearInterval(intervalQuotes);
      setIntervalQuotes(null);
    }
  };

  clearQuotesIntervalRef.current = clearQuotesInterval;
  requestInfoRef.current = requestInfo;

  const sortQuotes = (sortBy) => {
    updateSortByAuto(sortBy);
  };

  useEffect(() => {
    const iq = setInterval(() => {
      const requestInfo = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadAutoQuotes(requestToken);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesIntervalRef.current();
        }
      }
    }, 10000);

    setIntervalQuotes(iq);

    return () => {
      clearQuotesIntervalRef.current();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateAutoQuotesData(requestInfo, sortBy, preventivas_quotes, quotations);
    }
  }, [requestInfo, sortBy]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const modifyData = () => {
    navigate(`/quote/${requestInfo.product_id == 1 ? 'auto' : 'autocarro'}`, {
      state: { requestToken },
    });
  };

  const getAutoReportData = () => {
    fetchAutoReport(requestInfo.id);
  };

  const toggleAutoReportChart = () => {
    setShowConsulenzaReport(!showConsulenzaReport);
    getAutoReportData();
  };

  useEffect(() => {
    if (report !== null) {
      const newArrData = [];
      const newArrCats = [];
      for (let i = 0; i < report.length; i++) {
        const item = report[i];

        newArrData.push(parseFloat(item.value));
        newArrCats.push(item.name);
      }

      setChartData(newArrData);
      setChartCategories(newArrCats);
    }
  }, [report]);

  const optionsChart = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: chartData.map((value) => {
      if (value >= 66) return '#FF0000'; // Red
      if (value <= 25) return '#00A651'; // Green
      return '#FFE81A'; // Yellow
    }),
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val}%`;
      },
      offsetY: -20,
      style: {
        fontSize: '14px',
        colors: ['#282828'],
      },
    },
    xaxis: {
      categories: chartCategories,
    },
  };

  const series = [
    {
      name: 'Consulenza - Report',
      data: chartData,
    },
  ];

  // const leadSourceName = {
  //   0: 'Intermediario',
  //   1: 'Sito publico',
  // };

  useEffect(() => {
    if (requestInfo && requestInfo.is_expired === true) {
      navigate(`/quote/${requestInfo.product_id == 1 ? 'auto' : 'autocarro'}`, {
        state: { requestToken },
      });
      generalAlertError(
        'È necessario compilare nuovamente il preventivo!',
        'Link e scaduto!',
      );
    }
  }, [requestInfo]);

  const saveVehicleAmount = (type) => {
    addVehicleAmount(requestToken, refresh, vehicleAmount[type.id]);
  };

  if (loading || updateMarkupLoading) {
    return <PageLoading />;
  }

  const coverages = coveragesUtil.calculateCommonCoverages(
    requestInfo?.product_id ?? 1,
    quotes ?? [],
  );

  // const options = {
  //   arrowColor: '#fff',
  //   backgroundColor: '#fff',
  //   beaconSize: 36,
  //   overlayColor: 'rgba(0, 0, 0, 0.5)',
  //   spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  //   textColor: '#333',
  //   zIndex: 99999,
  // };

  const fetchQuotes = () => {
    loadAutoQuotes(requestToken);
  };

  const selectQuotation = (quoteId, closeButton, addDiscount) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteAutoToCart(
      quoteId,
      fetchQuotes,
      modifyData,
      closeButton,
      addDiscount,
    ));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteAutoFromCart(quoteId, fetchQuotes, modifyData));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let primaGuaranteesPrices = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (riskQuotations && riskQuotations[type.id] !== undefined) {
        for (let i = 0; i < riskQuotations[type.id].length; i++) {
          const item = riskQuotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    if (processingQuote.company_id === 2) {
      processingQuote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    return (
      quoteInProccessAmount
      + primaGuaranteesPrices
      + risksTotalAmount
    ).toFixed(2);
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    if (requestInfo.product_id == 1) {
      navigate(`/quotes/auto/purchase/${requestToken}`);
    } else if (requestInfo.product_id == 3) {
      navigate(`/quotes/autocarro/purchase/${requestToken}`);
    }
  };

  return error !== null ? (
    <ErrorComponent error={error} />
  ) : (
    <>
      {/* <Joyride
            steps={steps}
            run={run}
            continuous
            showBeacon={showBeacon}
            locale={{
              back: 'Indietro',
              close: 'Chiudi',
              last: 'Fine',
              next: 'Prossima',
              open: 'Apri il modale',
              restart: 'Riavvia il tour ',
              skip: 'Salta',
            }}
            styles={{
              options,
              buttonNext: {
                backgroundColor: '#00a651',
                outline: 'none',
              },
              buttonBack: {
                color: '#00a651',
                outline: 'none',
              },
              beaconInner: {
                backgroundColor: '#00a651',
              },
              beaconOuter: {
                borderColor: '#00a651',
                backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
              },
              buttonSkip: {
                outline: 'none',
              },
              buttonClose: {
                outline: 'none',
              },
            }}
            callback={(data) => handleCallbackRcQuotes(data, steps, setState)}
            showSkipButton
            showProgress
            disableScrolling
          /> */}
      {showGuaranteesModal && (
        <GuaranteesModal
          setShowGuaranteesModal={setShowGuaranteesModal}
          coverages={coverages}
          requestToken={requestToken}
        />
      )}

      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0 purchase-quote-first-step">
              <VehicleInfoCard
                insuranceRequest={requestInfo}
                modificaDati={modifyData}
              />
            </div>
            <div className="col-12 col-xxl-8 purchase-quote-second-step">
              <GuaranteesDefaultStateStyles>
                {processingQuote === null ? (
                  <h5 className="fw-semibold pt-2 ps-lg-2">
                    Seleziona Le Quotazioni Che Vuoi Aquistare
                  </h5>
                ) : (
                  <div className="container mt-4">
                    <ul className="list-unstyled">
                      {processingQuote !== null && (
                        <li>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>
                              {processingQuote.company !== null
                                ? processingQuote.company.name
                                : processingQuote.preventivas_company
                                  .company_name}
                              {' '}
                              <span className="text-success">
                                (
                                {
                                  quotationTypes.find(
                                    (item) => item.id === processingQuote.quote_type,
                                  ).name
                                }
                                )
                              </span>
                            </h5>
                            <h5 className="fw-bold">
                              {parseFloat(processingQuote.amount ? processingQuote.amount : 0).toFixed(2)}
                              {' '}
                              €
                            </h5>
                          </div>
                        </li>
                      )}
                      {quotationTypes
                        .filter((t) => t.id !== 'res_civile')
                        .map((type, index) => (
                          <React.Fragment key={index}>
                            {riskQuotations && riskQuotations[type.id]
                              ? riskQuotations[type.id].map((quote, i) => (
                                <li key={i}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5>
                                      {quote.company !== null
                                        ? quote.company.name
                                        : quote.preventivas_company
                                          .company_name}
                                      {' '}
                                      <span className="text-success">
                                        (
                                        {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                        )
                                      </span>
                                    </h5>
                                    <h5 className="fw-bold">
                                      {parseFloat(quote.amount).toFixed(2)}
                                      {' '}
                                      €
                                    </h5>
                                  </div>
                                </li>
                              ))
                              : ''}
                          </React.Fragment>
                        ))}
                      {processingQuote !== null
                        && processingQuote.company_id === 2
                        && processingQuote.guarantees.map((guarantee, index) => (
                          <li key={index}>
                            <div className="d-flex justify-content-between align-items-center">
                              <h5>
                                Prima.it
                                <span className="text-success">
                                  {' '}
                                  (
                                  {
                                    primaQuoteTypes.find(
                                      (type) => type.id === guarantee.guarantees_slug,
                                    ).name
                                  }
                                  )
                                </span>
                              </h5>
                              <h5 className="fw-bold">
                                {parseFloat(guarantee.amount).toFixed(2)}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        ))}
                      <hr />
                      <li>
                        <div className="d-flex justify-content-end align-items-center">
                          <h5 className="me-5 fw-bold">Totale:</h5>
                          <h5 className="fw-bold">
                            {' '}
                            {getTotal()}
                            {' '}
                            €
                          </h5>
                        </div>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-success btn-lg rounded-4 px-5 fw-bold"
                        onClick={() => handleRequestSave()}
                      >
                        Aquista
                      </button>
                    </div>
                  </div>
                )}
              </GuaranteesDefaultStateStyles>
              {/* <GuaranteesDefaultState setShowGuaranteesModal={setShowGuaranteesModal} quotes={quotes} /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <EstimateContainer>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <div className="d-flex align-items-center justify-content-start flex-column flex-xxl-row mt-2">
                      <div className="selling-distance mt-2 mt-xxl-0 purchase-quote-third-step">
                        {requestInfo.product_id === '1' && (
                          <ConsulenzaReportButton
                            className="btn btn-success m-2"
                            onClick={() => toggleAutoReportChart()}
                          >
                            Conzulenza Report
                          </ConsulenzaReportButton>
                        )}
                        {/* <div className="d-flex align-items-center justify-content-start flex-column flex-xxl-row mt-2">
                          <div className="selling-distance mt-2 mt-xxl-0">
                            {requestInfo.selling_point === 0
                              && (
                                <>
                                  <SellingDistanceButton className="btn btn-success mb-2" onClick={() => setShowSellingDistanceModal(true)}>Crea link per vendita a distanza</SellingDistanceButton>
                                  {showSellingDistanceModal === true
                                    && (
                                      <SellingDistanceModal
                                        requestToken={requestToken}
                                        closeModal={() => setShowSellingDistanceModal(false)}
                                        loadResults={() => loadAutoQuotes(requestToken)}
                                      />
                                    )}

                                </>
                              )}
                            {requestInfo.selling_point === 1
                              && (
                                <div className="d-flex align-items-center ">
                                  <div className="btn btn-success m-2" onClick={() => copySellingDistanceLink()} title="Copia collegamento per il cliente">
                                    <FontAwesomeIcon icon={faCopy} />
                                  </div>
                                  <SellingDistanceLink>{getSellingDistanceLink()}</SellingDistanceLink>
                                </div>
                              )}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {showConsulenzaReport ? (
                  loadingReport ? (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Caricando...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <h4 className="text-center fw-bold mb-0">
                        Analisi Bisogni Cliente
                      </h4>
                      <Chart
                        options={optionsChart}
                        series={series}
                        height="350px"
                        type="bar"
                      />
                    </div>
                  )
                ) : (
                  ''
                )}
                {/* <div className="d-flex flex-column align-items-end justify-content-end gap-2">
                      <div>
                        {localStorage.getItem('quoteNewResult') === 'done' && (
                          <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                            <button className="btn btn-success px-2 py-1">Tutorial</button>
                          </div>
                        )}
                      </div>
                    </div> */}
              </EstimateContainer>
            </div>
          </div>

          <RiskContainer className="mb-4 ">
            {processingQuote !== null && processingQuote.company_id === 2
              ? [{ id: 'res_civile', name: 'Responsabilita Civile' }].map(
                (type, index) => (
                  <React.Fragment key={index}>
                    {quotations && quotations[type.id] && (
                    <RiskCard
                      className={riskCardStyles(
                        requestInfo,
                        type,
                        riskQuotations,
                      )}
                    >
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <SelectedQuotesCards
                        processingQuote={processingQuote}
                        type={type}
                        riskQuotations={riskQuotations}
                        refresh={refresh}
                        requestInfo={requestInfo}
                      />
                      <ButtonComponentLogic
                        type={type}
                        requestInfo={requestInfo}
                        processingQuote={processingQuote}
                        removeItemFromCart={removeItemFromCart}
                        riskQuotations={riskQuotations}
                      />
                    </RiskCard>
                    )}
                  </React.Fragment>
                ),
              )
              : quotationTypes.map((type, k) => (
                <React.Fragment key={k}>
                  {quotations && quotations[type.id] ? (
                    <RiskCard
                      className={riskCardStyles(
                        requestInfo,
                        type,
                        riskQuotations,
                      )}
                    >
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <SelectedQuotesCards
                        processingQuote={processingQuote}
                        type={type}
                        refresh={refresh}
                        requestInfo={requestInfo}
                        riskQuotations={riskQuotations}
                      />

                      <ButtonComponentLogic
                        type={type}
                        processingQuote={processingQuote}
                        removeItemFromCart={removeItemFromCart}
                        requestInfo={requestInfo}
                        riskQuotations={riskQuotations}
                      />
                    </RiskCard>
                  ) : [
                    'furto_incendio',
                    'eventi_naturali',
                    'eventi_socio_politici',
                  ].includes(type.id) && requestInfo.product_id == 1 ? (
                    <RiskCard
                      className={riskCardStyles(
                        requestInfo,
                        type,
                        riskQuotations,
                      )}
                    >
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      <div className=" my-auto">
                        <FormInputText
                          label="Inserisci valore del veicolo"
                          type="number"
                          value={vehicleAmount[type.id]}
                          onWheel={(e) => e.target.blur()} // Remove focus from input to prevent scroll change
                          onFocus={(e) => e.target.addEventListener('wheel', (event) => event.preventDefault())}
                          onBlur={(e) => e.target.removeEventListener('wheel', (event) => event.preventDefault())}
                          onChange={(value) => setVehicleAmount({
                            ...vehicleAmount,
                            [type.id]: value,
                          })}
                        />
                      </div>
                      {vehicleAmount[type.id].length >= 3 && (
                      <button
                        className="btn btn-success rounded-4 py-2 px-4"
                        onClick={() => saveVehicleAmount(type)}
                      >
                        Salva
                      </button>
                      )}
                    </RiskCard>
                    ) : null}
                </React.Fragment>
              ))}
            {processingQuote !== null && processingQuote.company_id === 2 && (
              <>
                {processingQuote?.quotation_data_prima?.quotation_data_prima_guarantees.map(
                  (quote) => (
                    <>
                      {quote.slug !== 'rca' && (
                        <RiskCard
                          className={riskCardPrimaStyles(
                            primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            ),
                            processingQuote,
                          )}
                        >
                          <div className="p-3 me-auto">
                            <h5>
                              {
                                primaQuoteTypes.find(
                                  (type) => type.id === quote.slug,
                                ).name
                              }
                            </h5>
                          </div>
                          <SelectedQuotesCardsPrima
                            processingQuote={processingQuote}
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                          />

                          <ButtonComponentLogicPrima
                            type={primaQuoteTypes.find(
                              (type) => type.id === quote.slug,
                            )}
                            processingQuote={processingQuote}
                            requestInfo={requestInfo}
                          />
                        </RiskCard>
                      )}
                    </>
                  ),
                )}
              </>
            )}
          </RiskContainer>

          {quotationTypes.map((type, index) => (
            <ModalQuotes
              refresh={refresh}
              processingQuote={processingQuote}
              key={index}
              type={type}
              addingQuote={addingQuote}
              sortBy={sortBy}
              sortQuotes={sortQuotes}
              handleCurrentQuotesDisplay={handleCurrentQuotesDisplay}
              currentQuotes={currentQuotes}
              requestInfo={requestInfo}
              counter={counter}
              quotations={quotations}
              requestToken={requestToken}
              selectQuotation={selectQuotation}
              guaranteesPrima={guaranteesPrima}
              preventivas_quotes={preventivas_quotes}
              riskQuotes={riskQuotations}
            />
          ))}
          {processingQuote !== null
            && processingQuote.company_id === 2
            && primaQuoteTypes.map((type, index) => (
              <ModalQuotesPrima
                processingQuote={processingQuote}
                key={index}
                type={type}
                addingQuote={addingQuote}
                sortBy={sortBy}
                sortQuotes={sortQuotes}
                requestInfo={requestInfo}
                requestToken={requestToken}
                guaranteesPrima={guaranteesPrima}
              />
            ))}
          {failedQuotes !== null && failedQuotes.length > 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>
                Queste compagnie non hanno risposto con una quotazione.
              </FailedQuoteTitle>
              {failedQuotes.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img
                      src={BACKEND_BASE_URL + quote.company.logo}
                      alt={`${quote.company.name} Logo`}
                    />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </div>
      </RcQuotesStyles>
    </>
  );
}

export default RcQuotes;
