import axe from './axiosClient';
import { backOfficeAxe } from './backofficeClient';
import { downloadFile } from './axeHelpers';

export const modifyRequestData = (requestToken) => axe.get(`/insurance-request/${requestToken}/modify-data`);

export const modifyRequestDataBackoffice = (requestToken) => backOfficeAxe.get(`/insurance-request/${requestToken}/modify-data`);

export const updateActiveCustomer = (customerId, data) => axe.post(`/active-customers/${customerId}/update`, data);

export const greeniaPrivacyPolicy = async () => {
  const response = await axe.get('/greenia-privacy-policy', {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    return;
  }

  downloadFile(response.data);
};

export const downloadResourceDocs = async (id, professionId) => {
  const response = await axe.get(
    `/download-resource-document/${id}/${professionId}`,
    {
      responseType: 'blob',
    },
  );

  if (response.status !== 200) {
    return;
  }

  downloadFile(response.data);
};

export const deleteDocument = (documentId) => axe.post(`/document/delete/${documentId}`, {});

export const apiDownloadDocument = (documentId) => axe.get(`/document/${documentId}/download`);

export const apiDownloadBrokerDocument = (documentId) => axe.get(`/download-broker-document/${documentId}`);

export const apiDownloadBrokerReport = (documentId) => axe.get(`/download-broker-report/${documentId}`);

export const apiDownloadBrokerBill = (compensation_bill_id) => axe.get(`/download-broker-bill/${compensation_bill_id}`);

export const apiDownloadBrokerCompensationProof = (compensation_proof_id) => axe.get(`/download-broker-compensation-proof/${compensation_proof_id}`);

export const uploadDocument = (data) => axe.post('/upload-document', data);

export const documentMethodManualUpload = (quotationId, documentId, data) => axe.post(
  `/quotations/${quotationId}/manually/sign/documents/${documentId}/upload-signed`,
  data,
);

export const apiUploadOptionalDocument = (data) => axe.post('/optional-documents/upload', data);

export const apiDeleteOptionalDocument = (documentId) => axe.post(`/optional-documents/delete/${documentId}`, {});

export const apiDownloadOptionalDocument = (documentId) => axe.get(`/optional-documents/${documentId}/download`);

export const apiDownloadAfterSaleOptionalDocument = (documentId) => axe.get(`/after-sale-optional-documents/${documentId}/download`);

export const apiOptionalDocumentMethodManualUpload = (quotationId, documentId, data) => axe.post(
  `/quotations/${quotationId}/manually/sign/optional-documents/${documentId}/upload-signed`,
  data,
);
export const apiAfterSaleOptionalDocumentMethodManualUpload = (documentId, data) => axe.post(
  `/manually/sign/after-sale-optional-documents/${documentId}/upload-signed`,
  data,
);

export const apiDownloadManuallyAddedQuotationDocument = (documentId) => axe.get(`/manually-added-quotation-documents/${documentId}/download`);
export const apiDownloadCommercialReportDocument = (request_id) => axe.get(`/commercial-company-report/${request_id}/download`);

export const apiDownloadPogDocument = (pog_id) => axe.get(`/pogs/${pog_id}/download-document`);
export const apiAcceptPog = (pog_id) => axe.get(`/pogs/${pog_id}/accept-pog`);
