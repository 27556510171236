import styled from 'styled-components';

export const SaluteModalStyles = styled.div`

    &.custom-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1060;
        overflow: auto;
    }

    & .custom-modal-content {
    max-width: 1000px;
    width: 100%;
    max-height: 92%;
    border-radius: 0.5rem;
    overflow: auto;
    position: relative;
    }
`;
