import styled from 'styled-components';

export const RiskCardSelectedStyles = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 250px;
  height: 100%;
  width: 100%;
  position: relative;

  &.selected {
    border: 2px solid #00a551;
  }

  @media only screen and (max-width: 1200px) {
    padding: 15px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftCol = styled.div``;

export const LogoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
  }
`;

export const LogoResultContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 70px;
  }

  @media only screen and (max-width: 1200px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
`;

export const PriceResult = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #00a551;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const QuoteInformationResultContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  @media only screen and (max-width: 1200px) {
    gap: 10px;
    margin-top: 10px;
  }
`;

export const QuoteInformationResultItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
  }
`;
