import React, { useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import user from '../../assets/images/user.png';
import { greeniaPrivacyPolicy, modifyRequestDataBackoffice } from '../../services/axios-client/axeCommons';
import getMaxAllowedBirthday from '../../common/helpers/getMaxAllowedBirthday';
import { genders, metlifeMenoMaleGold } from '../../common/constants';
import PageLoading from '../../common/components/elements/PageLoading';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import {
  infortuniChangeIndex,
  infortuniGetInitialData,
  infortuniSubmitAnswers,
  infortuniUpdateFormData,
  resetInfortuniForm,
} from '../../features/formsBackoffice/formsBackofficeActions';
import { MetlifeStyles } from './BackOfficeInfortuni.styles';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { Layout } from './components/Layout/Layout';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import FormReactSelectResidenceInput from '../../common/formInputs/FormReactSelectResidenceInput';
import { generalAlertError } from '../../common/utils/alerts';

const schema = yup.object({
  source: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il source!'),
  product: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il prodotto!'),
  name: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il nome!')
    .matches(
      /^[a-zA-Z ]+$/,
      'Non sono ammessi numeri o caratteri speciali!',
    ),
  surname: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci il cognome!')
    .matches(
      /^[a-zA-Z ]+$/,
      'Non sono ammessi numeri o caratteri speciali!',
    ),
  date_of_birth: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data di nascita!')
    .required('Il campo è obbligatorio. Seleziona data di nascita!')
    .test('valid-date', 'La data di nascita non è valida', (value) => {
      if (!value) return true;
      const date = moment(value, 'YYYY-MM-DD', true);
      const year = date.year();
      const currentYear = new Date().getFullYear();

      if (year > currentYear - 18 || year < 1935) {
        return false;
      }
      return date.isValid();
    }),
  commune_of_birth_code: yup
    .string()
    .nullable()
    .when(['born_abroad'], ([born_abroad], schema) => (
      (!born_abroad)
        ? schema.required('Il campo è obbligatorio. Inserisci luogo di nascita')
        : schema
    )),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(
      /^[3]{1}[0-9]{8,9}$/,
      'Il numero non e corretto',
    ),
  email: yup.string()
    .matches(/^[a-zA-Z0-9.@]+$/, 'Sono ammessi solo lettere (a-z), numeri (0-9), punti (.) e (@).')
    .required('Il campo è obbligatorio. Inserisci Email!'),
  residence_commune_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona residenza!'),
  province_of_birth_code: yup
    .string()
    .nullable()
    .when(['born_abroad'], ([born_abroad], schema) => (
      (!born_abroad)
        ? schema.required('Il campo è obbligatorio. Inserisci luogo di nascita')
        : schema
    )),
  born_abroad: yup
    .boolean()
    .required('Il campo è obbligatorio. Seleziona se nato al estero!'),
  country_of_birth_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona il stato estero!'),
  address: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci indirizzo!'),
  postal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice postale!')
    .matches(/^[0-9]{5}$/),
  fiscal_code: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci codice fiscale!'),
  residence_province_code: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona provincia di residenza!'),
  civil_status_id: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona il stato civile!'),
  education_level_id: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona diploma!'),
  profession_id: yup
    .string()
    .required('Il campo è obbligatorio. Seleziona professione!'),
  existing_customer: yup.boolean(),
  active_customer_id: yup.number(),
  policy_effective_date: yup
    .date()
    .typeError('Il campo è obbligatorio. Seleziona data decorrenza!')
    .required('Il campo è obbligatorio. Seleziona data decorrenza!')
    .min(new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')), 'La data è nel passato')
    .max(
      new Date(moment().add(30, 'day').format('YYYY-MM-DD')),
      'La data è nel futuro',
    ),
  metlife: yup.object({
    plan: yup
      .string()
      .required('Il campo è obbligatorio. Seleziona il plan data!'),
    max: yup.string().required('Il campo è obbligatorio. Seleziona massimale!'),
    extensions: yup.array(yup.string().required()),
    ulc: yup.string(),
    rsm: yup.string(),
  }),
  privacies: yup.array(yup.number()),
});

function BackOfficeInfortuni() {
  const { piano, massimale, estensioni } = metlifeMenoMaleGold;
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      source: 'greenia.it',
      product: 'infortuni',
      name: '',
      surname: '',
      date_of_birth: '',
      commune_of_birth_code: '',
      gender: '',
      phone: '',
      email: '',
      residence_commune_code: null,
      province_of_birth_code: null,
      born_abroad: false,
      country_of_birth_code: 'Z000',
      address: '',
      postal_code: '',
      fiscal_code: '',
      residence_province_code: '',
      civil_status_id: '',
      education_level_id: '',
      profession_id: '',
      existing_customer: false,
      active_customer_id: null,
      policy_effective_date: '',
      metlife: {
        plan: '',
        max: '',
        extensions: [],
        ulc: '',
        rsm: '',
      },
      privacies: [],
    },
  });

  const state = useSelector((store) => store.formsBackoffice.infortuni);
  const {
    loading,
    states,
    municipalities,
    formData,
    dataPrivacy,
    index,
    isEntry,
  } = state;
  const data = watch();

  useEffect(() => {
    infortuniGetInitialData();

    return () => {
      resetInfortuniForm();
    };
  }, []);

  useEffect(() => {
    const values = getValues();

    if (values !== undefined) {
      const objectKeys = Object.keys(values);

      objectKeys.forEach((key) => {
        if (values[key] !== '' && values[key] !== null) {
          setValue(key, values[key], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, []);

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: data.name,
      surname: data.surname,
      gender: data.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (data.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === data.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === data.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = data.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const handleChange = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (data.metlife.max) {
      if (data.metlife.max === '50000') {
        infortuniUpdateFormData({
          isEntry: true,
        });
      } else {
        infortuniUpdateFormData({
          isEntry: false,
        });
      }
    }
  }, [data.metlife.max]);

  const prevButton = () => {
    if (index > 1) {
      infortuniChangeIndex(index - 1);
    }
  };

  useEffect(() => {
    const callAsync = async () => {
      const {
        data: { data: { personalData, planData } },
      } = await modifyRequestDataBackoffice(params.requestToken);
      handleChange(personalData);
      handleChange({
        'metlife.max': planData.max,
        'metlife.extensions': planData.extensions,
        'metlife.plan': planData.plan,
        'metlife.rsm': planData.rsm,
        'metlife.ulc': planData.ulc,
      });
    };
    callAsync();
  }, []);

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...data }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const nextButton = async () => {
    if (index === 1) {
      const res = await trigger(
        [
          'name',
          'surname',
          'date_of_birth',
          'commune_of_birth_code',
          'gender',
          'phone',
          'email',
          'residence_commune_code',
          'province_of_birth_code',
          'born_abroad',
          'country_of_birth_code',
          'address',
          'postal_code',
          'residence_province_code',
          'civil_status_id',
          'education_level_id',
          'profession_id',
          'policy_effective_date',
        ],
        { shouldFocus: true },
      );

      if (res === true) {
        const { error, message } = validateDataPrivacy();

        if (error) {
          generalAlertError(message);
          return;
        }

        infortuniChangeIndex(index + 1);
        setTimeout(() => window.scrollTo(0, 0), 500);
        return;
      }
    }

    if (index === 2) {
      const res = await trigger(['metlife.max', 'metlife.plan']);

      if (res === true) {
        const _data = { ...data };
        _data.fiscal_code = getFiscalCode(data.date_of_birth);
        _data.policy_effective_date = convertToItalianDate(
          data.policy_effective_date,
        );
        _data.date_of_birth = convertToItalianDate(data.date_of_birth);
        _data.metlife.ulc = data.metlife.extensions.includes('ustioni') ? 1 : 0;
        _data.metlife.rsm = data.metlife.extensions.includes('rimborso')
          ? 1
          : 0;

        infortuniSubmitAnswers(_data, navigate, params.requestToken);
      }
    }
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...data.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      handleChange({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...data.privacies];
    answersNewArray.push(item.id);
    handleChange({
      privacies: answersNewArray,
    });
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <MetlifeStyles>
        {index === 1 && (
          <>
            <div className="row">
              <div className="col-12 p-4">
                <div className="d-flex align-items-center justify-content-center">
                  <img src={user} alt="User_Icon" className="img-fluid me-4" />
                  <div>
                    <h3 className="h3 fw-bold">Dati Personali</h3>
                    <h6 className="h6 text-muted">Compilare i seguenti dati</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-container">
              <FormInputText
                label="Nome"
                registration={register('name')}
                error={errors.name?.message}
                valid={isValid('name')}
              />

              <FormInputText
                label="Cognome"
                registration={register('surname')}
                error={errors.surname?.message}
                valid={isValid('surname')}
              />
              <FormInputRadio
                label="Genere"
                options={genders.filter((gender) => gender.value !== 'G')}
                name="gender"
                registration={register('gender')}
                error={errors.gender?.message}
              />
              <FormInputDate
                label="Data di nascita"
                registration={register('date_of_birth')}
                minDate="1935-01-01"
                maxDate={getMaxAllowedBirthday()}
                error={errors.date_of_birth?.message}
                valid={isValid('date_of_birth')}
              />
              <FormReactSelectInputBirthPlace
                states={states}
                options={municipalities}
                label="Luogo di nascita"
                name="commune_of_birth_code"
                values={{
                  commune_of_birth_code: getValues().commune_of_birth_code,
                  province_of_birth_code: getValues().province_of_birth_code,
                  born_abroad: getValues().born_abroad,
                  country_of_birth_code: getValues().country_of_birth_code,
                }}
                onChange={(value) => handleChange({
                  commune_of_birth_code: value.commune_of_birth_code,
                  province_of_birth_code: value.province_of_birth_code,
                  born_abroad: value.born_abroad,
                  country_of_birth_code: value.country_of_birth_code,
                })}
                valid={isValid('commune_of_birth_code')}
                error={errors.commune_of_birth_code?.message}
              />
              <FormInputText
                label="Telefono"
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
              />
              <FormInputText
                label="E-Mail"
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
              />
              <FormReactSelectResidenceInput
                label="Residenza"
                placeholder="Comune di Residenza"
                options={municipalities}
                values={{
                  residence_commune_code: getValues().residence_commune_code,
                  residence_province_code: getValues().residence_province_code,
                  postal_code: getValues().postal_code,
                }}
                onChange={(item) => handleChange({
                  residence_commune_code: item.residence_commune_code,
                  residence_province_code:
                    item.residence_province_code,
                  postal_code: item.postal_code ?? '',
                })}
                error={errors.residence_commune_code?.message}
                valid={isValid('residence_commune_code')}
              >
                <FormInputText
                  placeholder="-Codice Postale-"
                  label="Codice Postale"
                  registration={register('postal_code')}
                  error={errors.postal_code?.message}
                  valid={isValid('postal_code')}
                />
              </FormReactSelectResidenceInput>
              <FormInputText
                label="Indirizzo"
                registration={register('address')}
                error={errors.address?.message}
                valid={isValid('address')}
              />

              <FormInputSelect
                placeholder="Stato civile"
                label="Stato civile"
                options={formData.marital_statuses}
                registration={register('civil_status_id')}
                error={errors.civil_status_id?.message}
                value={getValues().civil_status_id}
                valid={isValid('civil_status_id')}
              />

              <FormInputSelect
                placeholder="Titolo di studio"
                label="Titolo di studio"
                options={formData.qualifications}
                registration={register('education_level_id')}
                error={errors.education_level_id?.message}
                value={getValues().education_level_id}
                valid={isValid('education_level_id')}
              />

              <FormInputSelect
                placeholder="Professione"
                label="Professione"
                options={formData.professions}
                registration={register('profession_id')}
                error={errors.profession_id?.message}
                value={getValues().profession_id}
                valid={isValid('profession_id')}
              />

              <FormInputDate
                minDate={getAllowedPolicyEffectDate('min')}
                maxDate={getAllowedPolicyEffectDate('max')}
                name="policy_effective_date"
                label="Data di inizio della copertura della polizza"
                paragraph="Date valide: da oggi a 30 giorni da oggi"
                registration={register('policy_effective_date')}
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
              />
            </div>

            <h5 className="text-center">Informativa Privacy e IVASS</h5>

            <div className="text-center text-decoration-underline mt-2">
              <a onClick={() => greeniaPrivacyPolicy()} style={{ cursor: 'pointer' }}>
                <small className="text-center">Greenia Privacy Policy</small>
              </a>
            </div>

            {dataPrivacy.map((privacy, i) => (
              <InputPrivacy
                required={privacy.required}
                label={privacy.content}
                name={privacy.type}
                id={privacy.type}
                key={i}
                checked={data?.privacies?.includes(privacy.id)}
                onChange={(value) => privacyItemChangeHandler(value, privacy)}
              />
            ))}
          </>
        )}
        {index === 2 && (
          <>
            <div className="row">
              <div className="col-12 col-md-12 bg-light p-4 mb-4">
                <h3 className="h2 fw-bold">MetLife</h3>
                <p>Insieme a te nella vita, passo dopo passo.</p>
              </div>
            </div>
            <div className="row row-width">
              <div className="col-md-4 col-12 mb-5">
                <h4 className="h3 pb-2 underline ps-2 mb-4">Piano</h4>
                {piano.map((el, index) => (
                  <div className="form-check my-3" key={index}>
                    <input
                      className="form-check-input radio-custom"
                      type="radio"
                      name="plan"
                      id="plan"
                      value={el.id}
                      checked={el.id === getValues().metlife.plan}
                      {...register('metlife.plan')}
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="plan"
                    >
                      {el.label}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-md-4 col-12 mb-5">
                <h4 className="h3 pb-2 underline ps-2 mb-4">Massimale</h4>
                {massimale.map((max, index) => (
                  <div className="form-check my-3" key={index}>
                    <input
                      className="form-check-input radio-custom"
                      type="radio"
                      name="max"
                      id="max"
                      value={max.id}
                      checked={max.id === getValues().metlife.max}
                      {...register('metlife.max')}
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="max"
                    >
                      &euro;
                      {' '}
                      {Number(max.label).toLocaleString('it-IT')}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-md-4 col-12 mb-5">
                <h4 className="h3 pb-2 underline ps-2 mb-4">Estensioni</h4>
                {isEntry ? (
                  <p>Per massimale 50.000 Euro non chi sonno estensioni!</p>
                ) : (
                  estensioni.map((ext, index) => (
                    <div
                      className="form-check d-flex align-items-center my-3"
                      key={index}
                    >
                      <input
                        className="form-check-input radio-custom"
                        type="checkbox"
                        name="extension"
                        id="extension"
                        value={ext.id}
                        checked={getValues().metlife.extensions.includes(
                          ext.id,
                        )}
                        {...register('metlife.extensions')}
                      />
                      <label
                        className="form-check-label fw-semibold"
                        htmlFor="extension"
                      >
                        {ext.label}
                      </label>
                    </div>
                  ))
                )}
              </div>
            </div>
          </>
        )}
        <div className="footer-buttons">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            <button className="btn btn-questionnaire" onClick={nextButton}>
              {index === 2 ? 'Vai ai preventivi' : 'Continua'}
            </button>
          </div>
        </div>
      </MetlifeStyles>
    </Layout>
  );
}

export default BackOfficeInfortuni;
