import React, { useRef, useState } from 'react';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol,
  LogoContainer,
  LogoPriceContainer,
  RightCol,
  RiskCardStyles,
  SavedButton,
} from './RiskCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import ModalsImported from './riskCardReusable/ModalsImported';
import RiskPriceComponent from './riskCardReusable/RiskPriceComponent';
import RiskCompanyInfoComponent from './riskCardReusable/RiskCompanyInfoComponent';
import RiskQuoteInfoComponent from './riskCardReusable/RiskQuoteInfoComponent';
import { Chat } from '../../chats/MiniChat/MiniChat';
// import SparklesIcon from '../../../../assets/images/icons/SparklesIcon'

function RiskCard({
  quote, type, selectQuotation, riskQuotes,
}) {
  const [extraText, setExtraText] = useState(null);
  const [showModalAssistenza, setShowModalAssistenza] = useState(false);
  const [showModalGlobalTutela, setShowModalGlobalTutela] = useState(false);
  const [showModalGlobalInfortuni, setShowModalGlobalInfortuni] = useState(false);
  const [showModalGlobalCristalli, setShowModalGlobalCristalli] = useState(false);
  const [showModalEuropeAssistenza, setShowModalEuropeAssistenza] = useState(false);
  const [showModalEuropeTutela, setShowModalEuropeTutela] = useState(false);
  const [showModalEuropeCristalli, setShowModalEuropeCristalli] = useState(false);
  const [showModalEuropeCollisione, setShowModalEuropeCollisione] = useState(false);
  const [showModalTutelaArag, setShowModalTutelaArag] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();

  const openModal = () => {
    if (quote.quote_type === 'assistenza_stradale') {
      setShowModalAssistenza(!showModalAssistenza);
    }
    if (quote.quote_type === 'tutela_legale' && quote.company_id === 16) {
      setShowModalGlobalTutela(!showModalGlobalTutela);
    }
    if (quote.quote_type === 'tutela_legale' && quote.company_id === 15) {
      setShowModalTutelaArag(!showModalTutelaArag);
    }
    if (quote.quote_type === 'infortuni_conducente') {
      setShowModalGlobalInfortuni(!showModalGlobalInfortuni);
    }
    if (quote.quote_type === 'cristalli') {
      setShowModalGlobalCristalli(!showModalGlobalCristalli);
    }
  };

  const openModalEurope = () => {
    if (quote.quote_type === 'assistenza_stradale') {
      setShowModalEuropeAssistenza(!showModalEuropeAssistenza);
    }
    if (quote.quote_type === 'tutela_legale') {
      setShowModalEuropeTutela(!showModalEuropeTutela);
    }
    if (quote.quote_type === 'cristalli') {
      setShowModalEuropeCristalli(!showModalEuropeCristalli);
    }
    if (quote.quote_type === 'collisione') {
      setShowModalEuropeCollisione(!showModalEuropeCollisione);
    }
  };
  return (
    <RiskCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        {/* <button
          onClick={() => setOpenChat(true)}
          className="open-chat-button border-0  text-white bg-success rounded p-1"
          style={{ position: 'absolute', right: '30px', top: '30px' }}
        >
          <SparklesIcon width={40} height={40} />
        </button> */}
        <Chat
          ref={chatRef}
          openChat={openChat}
          setOpenChat={setOpenChat}
          company_id={quote.company_id}
          product_id={quote.product_id}
          quote_type={quote.quote_type}
          company_name={quote.company.name}
          extraText={extraText}
        />
        <ModalsImported
          quote={quote}
          showModalAssistenza={showModalAssistenza}
          setShowModalAssistenza={setShowModalAssistenza}
          showModalGlobalTutela={showModalGlobalTutela}
          setShowModalGlobalTutela={setShowModalGlobalTutela}
          showModalGlobalInfortuni={showModalGlobalInfortuni}
          setShowModalGlobalInfortuni={setShowModalGlobalInfortuni}
          showModalGlobalCristalli={showModalGlobalCristalli}
          setShowModalGlobalCristalli={setShowModalGlobalCristalli}
          showModalEuropeAssistenza={showModalEuropeAssistenza}
          setShowModalEuropeAssistenza={setShowModalEuropeAssistenza}
          showModalEuropeTutela={showModalEuropeTutela}
          setShowModalEuropeTutela={setShowModalEuropeTutela}
          showModalEuropeCristalli={showModalEuropeCristalli}
          setShowModalEuropeCristalli={setShowModalEuropeCristalli}
          showModalEuropeCollisione={showModalEuropeCollisione}
          setShowModalEuropeCollisione={setShowModalEuropeCollisione}
          showModalTutelaArag={showModalTutelaArag}
          setShowModalTutelaArag={setShowModalTutelaArag}
        />
        <LogoPriceContainer>
          <LogoContainer>
            <img
              src={BACKEND_BASE_URL + quote.company.logo}
              alt={quote.company.name}
            />
          </LogoContainer>
          <RiskPriceComponent quote={quote} />
        </LogoPriceContainer>
        <RiskCompanyInfoComponent
          quote={quote}
          openModal={openModal}
          openModalEurope={openModalEurope}
        />
        <RiskQuoteInfoComponent
          quote={quote}
          type={type}
          setExtraText={setExtraText}
        />
      </LeftCol>
      <RightCol>
        <AquistaButtonsContainer>
          {riskQuotes
          && riskQuotes[type.id]
          && riskQuotes[type.id].find((q) => q.id === quote.id) !== undefined ? (
            <SavedButton>Aggiunto</SavedButton>
            ) : (
              <>
                {/* <button
                onClick={() => setOpenChat(true)}
                className='open-chat-button border-0  text-white bg-success rounded p-1'
                style={{ position: 'absolute', right: '30px', top: '30px' }}
              >
                <SparklesIcon width={40} height={40} />
              </button> */}
                <Chat
                  ref={chatRef}
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  company_id={quote.company_id}
                  product_id={quote.product_id}
                  quote_type={quote.quote_type}
                  company_name={quote.company.name}
                  extraText={extraText}
                />
                <AquistaButton
                  onClick={() => selectQuotation(quote.id)}
                  className="result-profession-sixth-step"
                >
                  Seleziona
                </AquistaButton>
              </>
            )}
        </AquistaButtonsContainer>
      </RightCol>
    </RiskCardStyles>
  );
}

export default RiskCard;
