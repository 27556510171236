import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  LeftSide,
  ModalBody,
  ModalContent,
  ModalHeader,
  ProfessionInfoModalStyles,
  RightSide,
  TextContainer,
  TitleBox,
} from './ProfessionInfoModal.styles';
import { CompanyInfoElements } from '../Cards/ProfessionCard.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import preventiveIcon from '../../../../assets/redesign_icons/preventivo-icon.svg';
import preContractIcon from '../../../../assets/redesign_icons/pre-contract-icon.svg';
import { downloadResourceDocs } from '../../../../services/axios-client/axeCommons';

function ProfessionInfoModal({
  setShowModal,
  docs,
  set_informativi,
  profession,
}) {
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <ProfessionInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon
              fontSize={34}
              icon={faTimes}
              cursor="pointer"
              onClick={() => closeModal()}
            />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>Qui puoi controllare le informazioni su questa offerta.</p>
            </TextContainer>
          </LeftSide>
          <RightSide>
            {docs[profession.profession] !== '' && (
              <CompanyInfoElements
                onClick={() => downloadResourceDocs(4, profession.profession)}
                title={`${docs[profession.profession]}`}
              >
                <img src={preventiveIcon} alt="" />
                <p>Questionario</p>
              </CompanyInfoElements>
            )}
            {set_informativi[profession.profession] !== '' && (
              <CompanyInfoElements
                onClick={() => downloadResourceDocs(8, profession.profession)}
                title={`${set_informativi[profession.profession]}`}
              >
                <img src={preContractIcon} alt="" />
                <p>Informativa Precontrattuale</p>
              </CompanyInfoElements>
            )}
          </RightSide>
        </ModalHeader>
        <ModalContent>
          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <p>
              <b>Tacito rinnovo:</b>
              {' '}
              Si
              <br />
              <b>Questionario assuntivo richiesto:</b>
              {' '}
              Si
              <br />
              <b>Forma della copertura:</b>
              {' '}
              Claims Made
              <br />
            </p>
            <hr />
            <p>
              <b>Ragione sociale:</b>
              {' '}
              TOKIO MARINE EUROPE S.A.
              <br />
              LARGO ARTURO TOSCANINI, 1 - 20122 - MILANO
              <br />
              <b>Codice IVASS:</b>
              {' '}
              D953R
              <br />
              <b>Codice Ania:</b>
              {' '}
              0657
              <br />
              <b>Numero di iscrizione:</b>
              {' '}
              I.00152
              <br />
              <b>Data di iscrizione:</b>
              {' '}
              24/08/2018
              <br />
              <b>Tipologia impresa:</b>
              {' '}
              IMPRESA DI ASSICURAZIONE CON SEDE LEGALE
              NEL SEE AMMESSA AD OPERARE IN ITALIA IN REGIME DI STABILIMENTO
              <br />
              <b>Albo:</b>
              {' '}
              Albo Imprese - Elenco I
              <br />
            </p>
            <hr />
            <p>
              La polizza di Responsabilità Civile Professionale è
              un'assicurazione che protegge il patrimonio del professionista dai
              rischi professionali connessi al normale svolgimento della propria
              attività. Dietro pagamento di un premio di polizza assicurativa
              annuo, la compagnia assicurativa garantisce il professionista
              dalle richieste di risarcimento presentate da terze parti
              danneggiate dall'operato dell'assicurato per
              {' '}
              <b>errori, negligenze od omissioni</b>
              {' '}
              durante l'esercizio della
              professione.
            </p>
            <p>
              Oltre ad essere
              {' '}
              <b>obbligatorio per legge dal 13 agosto 2013</b>
              {' '}
              per tutte le professioni ordinistiche, assicurare la propria
              attività
              {' '}
              <b>conviene</b>
              : grazie alla copertura garantita da una
              polizza di Responsabilità Civile Professionale su misura per la
              tua attività, puoi operare le scelte necessarie per lo svolgimento
              della tua professione in totale libertà e sicurezza.
            </p>
          </div>
        </ModalContent>
      </ModalBody>
    </ProfessionInfoModalStyles>
  );
}

export default ProfessionInfoModal;
