import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  CasaInfoModalStyles, LeftSide, ModalBody, ModalContent, ModalHeader,
  TextContainer,
  TitleBox,
} from './CasaInfoModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import { casaInfo } from '../../../constants';

function CasaInfoModal({ setShowModal, quote }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <CasaInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>

        </ModalHeader>
        <ModalContent>
          <div className="card-extra-information">
            <h3 className="mb-3">Informazioni</h3>

            {
            casaInfo.map((info) => (
              quote.quote_type === info.quote_type && (
              <>
                <p>{info.desc}</p>
                {info.limits.length > 0 && (
                <ul>
                  {info.limits.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                )}
                {info.numberList.length > 0 && (
                <ol>
                  {info.numberList.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
                )}
                {info.subText && (
                <p className="mt-3">{info.subText}</p>
                )}
                {info.extra && (
                <p className="mt-4">
                  <b>Estensione valida per tutte le Garanzie: </b>
                  {' '}
                  {info.extra}
                </p>
                )}
              </>
              )
            ))

        }

          </div>
        </ModalContent>
      </ModalBody>
    </CasaInfoModalStyles>
  );
}

export default CasaInfoModal;
