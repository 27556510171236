import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../common/components/elements/PageLoading';
import {
  addBackOfficeQuoteToCart,
  loadBackOfficeQuotes,
  removeBackOfficeQuoteFromCart, resetProfessionQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import { Layout } from '../components/Layout/Layout';
import ProfessionInfoCard from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard';
import { ProfessionQuotesStyles } from './BackOfficeRcProfessionQuotes.styles';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import { GuaranteesDefaultStateStyles } from '../components/common/Modal/GuaranteesDefaultStateTravel.styles';
import {
  RespCivileModal, RiskButton, RiskButtonRemove, RiskCard, RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import ProfessionRiskCard from '../../../common/components/redesignComponents/Cards/ProfessionRiskCard';
import { quotationTypes } from '../../../common/constants';
import ProfessionCard from '../../../common/components/redesignComponents/Cards/ProfessionCard';
import { confirmAlert } from '../../../common/utils/alerts';
import ProcessingQuoteSelectedProfession from '../../../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedProfession';
import TutelaSelectedProfession from '../../../common/components/redesignComponents/ResultPageComponents/TutelaSelectedProfession';
import AragCard from '../../../common/components/redesignComponents/Cards/AragCard';

const docs = {
  1: 'questionario_hcc_ingegneri.pdf',
  2: 'questionario_hcc_architetto.pdf',
  3: 'questionario_hcc_avvocato.pdf',
  4: 'questionario_hcc_Commercialista.pdf',
  5: 'questionario_hcc_consulente_del_lavoro.pdf',
  6: 'questionario_hcc_agente_immobiliare.pdf',
  7: 'questionario_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'questionario_hcc_amministratore_condominio.pdf',
  9: 'questionario_hcc_geometri.pdf',
  10: 'questionario_hcc_mediatore_creditizi.pdf',
  11: 'questionario_hcc_periti.pdf',
  12: 'questionario_hcc_tributarista.pdf',
  13: '',
  14: 'questionario_hcc_ag_commercio.pdf',
  15: 'questionario_hcc_agronomi.pdf',
  16: 'questionario_hcc_societa_di_edp.pdf',
  17: 'questionario_hcc_chimichi.pdf',
  18: 'questionario_hcc_dpo.pdf',
  19: 'questionario_hcc_geologi.pdf',
  20: 'questionario_hcc_miscellaneous.pdf',
  21: 'questionario_hcc_miscellaneous_energetica.pdf',
  22: 'questionario_hcc_psicologo.pdf',
  23: 'questionario_hcc_revisore_legale_dei_conti.pdf',
  24: 'questionario_hcc_singolo_componente_mediazione.pdf',
  25: 'questionario_hcc_tecnologo_alimentare.pdf',
  26: 'questionario_hcc_conformita.pdf',
};

const set_informativi = {
  1: 'set_informativo_hcc_ingegneri.pdf',
  2: 'set_informativo_hcc_architetto.pdf',
  3: 'set_informativo_hcc_avvocato.pdf',
  4: 'set_informativo_hcc_Commercialista.pdf',
  5: 'set_informativo_hcc_consulente_del_lavoro.pdf',
  6: 'set_informativo_hcc_agente_immobiliare.pdf',
  7: 'set_informativo_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'set_informativo_hcc_amministratore_condominio.pdf',
  9: 'set_informativo_hcc_geometri.pdf',
  10: 'set_informativo_hcc_mediatore_creditizi.pdf',
  11: 'set_informativo_hcc_periti.pdf',
  12: 'set_informativo_hcc_tributarista.pdf',
  13: '',
  14: 'set_informativo_hcc_ag_commercio.pdf',
  15: 'set_informativo_hcc_agronomi.pdf',
  16: 'set_informativo_hcc_societa_di_edp.pdf',
  17: 'set_informativo_hcc_chimichi.pdf',
  18: 'set_informativo_hcc_dpo.pdf',
  19: 'set_informativo_hcc_geologi.pdf',
  20: 'set_informativo_hcc_miscellaneous.pdf',
  21: 'set_informativo_hcc_miscellaneous_energetica.pdf',
  22: 'set_informativo_hcc_psicologo.pdf',
  23: 'set_informativo_hcc_revisore_legale_dei_conti.pdf',
  24: '',
  // "24": "set_informativo_hcc_singolo_componente_mediazione.pdf",
  25: 'set_informativo_hcc_tecnologo_alimentare.pdf',
  26: 'set_informativo_hcc_conformita.pdf',
};

const selectableExtensions = [
  'tlb',
  'tls',
  'tlg',
];

export default function BackofficeRcProfessionQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();

  const state = useSelector((store) => store.backofficeResults.back_office_quotes);
  const {
    loading,
    requestInfo,
    quotes,
    processingQuote,
    addingQuote,
    customer,
    risk_quotations,

  } = state;

  const [openModal, setOpenModal] = useState(false);

  const refresh = () => loadBackOfficeQuotes(requestToken);

  const modifyData = () => {
    navigate(`/backoffice/quote/${requestToken}/profession`);
  };

  useEffect(() => {
    loadBackOfficeQuotes(requestToken);

    return () => {
      resetProfessionQuotes();
    };
  }, []);

  const fetchQuotes = () => {
    loadBackOfficeQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addBackOfficeQuoteToCart(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeBackOfficeQuoteFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    const total = quoteInProccessAmount + risksTotalAmount;
    return total.toFixed(2);
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      {/* {openModal && <GuaranteesModalProfession setOpenModal={setOpenModal} profession={profession} extensions={extensions} requestToken={requestToken} selectableExtensions={selectableExtensions} />} */}
      <ProfessionQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-xxl-0">
              <ProfessionInfoCard modificaDati={modifyData} customer={customer} insuranceRequest={requestInfo} />
            </div>
            <div className="col-12 col-xxl-8">
              <GuaranteesDefaultStateStyles>
                <div className="container mt-4">
                  {processingQuote === null
                    ? <h5 className="fw-bold">Seleziona Le Quotazioni Che Vuoi Aquistare</h5>
                    : (
                      <>
                        <ul className="list-unstyled">
                          {processingQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {processingQuote.company.name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === processingQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(processingQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {quotationTypes
                            .filter((t) => t.id !== 'res_civile')
                            .map((type, index) => (
                              <React.Fragment key={index}>
                                {risk_quotations && risk_quotations[type.id]
                                  ? risk_quotations[type.id].map((quote, i) => (
                                    <li key={i}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5>
                                          {quote.company !== null
                                            ? quote.company.name
                                            : quote.preventivas_company
                                              .company_name}
                                          {' '}
                                          <span className="text-success">
                                            (
                                            {
                                            quotationTypes.find(
                                              (item) => item.id === quote.quote_type,
                                            ).name
                                          }
                                            )
                                          </span>
                                        </h5>
                                        <h5 className="fw-bold">
                                          {parseFloat(quote.amount).toFixed(2)}
                                          {' '}
                                          €
                                        </h5>
                                      </div>
                                    </li>
                                  ))
                                  : ''}
                              </React.Fragment>
                            ))}
                          <hr />
                          <li>
                            <div className="d-flex justify-content-end align-items-center">
                              <h5 className="me-5 fw-bold">
                                Totale:
                              </h5>
                              <h5 className="fw-bold">
                                {' '}
                                {getTotal()}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        </ul>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <button className="btn btn-success rounded-4 px-5 py-2">
                            Aquista
                          </button>
                        </div> */}
                      </>
                    )}
                </div>
              </GuaranteesDefaultStateStyles>
              {/* <GuaranteesDefaultState setShowGuaranteesModal={setOpenModal} quotes={requestInfo.quotations} extensions={extensions} extension_price={profession.extension_price} /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <EstimateContainer>
                {/* <h4>
                  Preventivi assicurazione
                  {' '}
                  <b>professionale</b>
                </h4>
                <p>
                  Inizio Copertura:
                  {' '}
                  {new Date(requestInfo.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                </p> */}
              </EstimateContainer>
            </div>
          </div>
          <RiskContainer className="mb-4 result-profession-fourth-step">
            {quotationTypes.map((type, index) => (
              <React.Fragment key={index}>
                {quotes && quotes[type.id] && (
                <RiskCard className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${requestInfo.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} ${requestInfo.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} ${(risk_quotations && risk_quotations[type.id] !== undefined) ? 'bg-success bg-opacity-25' : ''}`}>
                  <div className="p-3 me-auto">
                    <h5>{type.name}</h5>
                  </div>
                  {(processingQuote !== null && type.id === 'res_civile') && <ProcessingQuoteSelectedProfession processingQuote={processingQuote} requestInfo={requestInfo} refresh={refresh} profession={requestInfo.profession} />}

                  {risk_quotations
                      && risk_quotations[type.id] !== undefined
                      && risk_quotations[type.id].map((quote, idx) => (
                        <React.Fragment key={idx}>
                          {processingQuote !== null
                            && processingQuote.company_id !== 2
                            && type.id === quote.quote_type && (
                              <TutelaSelectedProfession tutelaLegaleQuote={quote} type={type} />
                          )}
                        </React.Fragment>
                      ))}

                  {risk_quotations
                      && risk_quotations[type.id] === undefined
                      && type.id !== 'res_civile'
                      && processingQuote !== null && (
                        <div
                          data-bs-toggle="modal"
                          data-bs-target={`#${type.id}`}
                          style={{ cursor: 'pointer' }}
                          className="alert alert-secondary"
                        >
                          Seleziona Quotazione!
                        </div>
                  )}

                  <div className="d-flex justify-content-end ms-auto gap-2">
                    {risk_quotations
                        && risk_quotations[type.id] !== undefined
                        && type.id !== 'res_civile'
                        && risk_quotations[type.id].map((quote, idx) => (
                          <React.Fragment key={idx}>
                            {type.id === quote.quote_type && (
                              <RiskButtonRemove
                                className="btn btn-danger"
                                onClick={() => removeItemFromCart(quote.id)}
                              >
                                Rimuovi
                              </RiskButtonRemove>
                            )}
                          </React.Fragment>
                        ))}

                    {risk_quotations
                        && risk_quotations[type.id] === undefined
                        && processingQuote !== null
                        && type.id !== 'res_civile' && (
                          <RiskButton
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target={`#${type.id}`}
                          >
                            Aggiungi
                          </RiskButton>
                    )}

                    {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}

                    {requestInfo.processing_quotation_id === null && type.id !== 'res_civile' && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}

                    {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
                  </div>
                </RiskCard>
                )}
              </React.Fragment>
            ))}
          </RiskContainer>
        </div>
        {quotationTypes.map((type, index) => (
          <RespCivileModal className="modal fade" id={type.id} tabIndex="-1" aria-labelledby={type.name} aria-hidden="true" key={index}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id={type.name}>{type.name}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" />
                </div>
                <div className="modal-body">
                  {addingQuote && (
                    <div className="d-flex align-items-center justify-content-between flex-column">
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Aggiungendo...</span>
                        </div>
                      </div>
                      <div className="ms-2">Aggiungendo...</div>
                    </div>
                  )}
                  {addingQuote === false && quotes[type.id] ? quotes[type.id].map((quote, index) => (
                    <div className="mt-3" key={index}>
                      {quote.quote_type === 'res_civile'
                        ? <ProfessionCard setOpenModal={setOpenModal} requestInfo={requestInfo} set_informativi={set_informativi} docs={docs} revealModal={openModal} extensions={requestInfo.profession.extensions} requestToken={requestToken} selectableExtensions={selectableExtensions} profession={requestInfo.profession} quoteId={quote.id} refresh={refresh} selectQuotation={selectQuotation} userData={quote} />
                        : quote.company_id === 15 ? <AragCard quotation={quote} requestInfo={requestInfo} type={type} selectQuotation={selectQuotation} /> : <ProfessionRiskCard quote={quote} requestInfo={requestInfo} selectQuotation={selectQuotation} />}
                    </div>
                  )) : addingQuote === false && (
                    <p>
                      Nessuna Quotazione
                      {type.name}
                      {' '}
                      Trovata.
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
                </div>
              </div>
            </div>
          </RespCivileModal>
        ))}
      </ProfessionQuotesStyles>
    </Layout>
  );
}
