import moment from 'moment';
import caller from './userSlice';
import StorageService from '../../services/StorageService';
import axe from '../../services/axios-client/axiosClient';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

const storage = new StorageService();

const serverError = 'Si è verificato un problema con il server';

export const attemptLogin = async ({ email, password }) => {
  caller.loginPending();

  try {
    const response = await managementAxe.post('/auth/login', {
      email,
      password,
    });
    if (response.status === 400 && response.data.error === 'account_disabled') {
      caller.loginRejected('L\'account è stato bloccato');
      return;
    }

    if (response.status === 400 && response.data.error === 'account_locked') {
      caller.loginRejected(`L'account è stato bloccato fino al: ${moment(response.data.locked_until).format('D MMMM, HH:mm')}`);
      return;
    }

    if (response.status === 401) {
      caller.loginRejected('Credenziali non valide');
      return;
    }

    if (response.status !== 200) {
      caller.loginRejected('Errore interno');
      return;
    }

    const { user, access_token: token, refresh_token } = response.data;

    storage.setUser(user);
    storage.setToken(token);
    storage.setRefreshToken(refresh_token);

    caller.loginSuccess({ user, token });
  } catch (err) {
    caller.loginRejected('Errore interno');
  }
};

export const brokerData = async (brokerId) => {
  caller.brokerDataPending();
  try {
    const response = await axe.get(`/${brokerId}`);

    if (response.status !== 200) {
      caller.brokerDataRejected(serverError);
      return;
    }

    caller.brokerDataSuccess({
      loading: false,
      broker: response.data.data.broker,
      documents: response.data.data.documents,
      reports: response.data.data.reports,
      bills: response.data.data.bills,
      broker_agent_accepted_pogs: response.data.data.broker_agent_accepted_pogs,
    });
  } catch (error) {
    caller.brokerDataRejected(serverError);
  }
};
