import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import moment from 'moment';
import { ProfileStyles } from './Profile.styles';
import appStorage from '../common/helpers/appStorage';
import { brokerData } from '../features/user/userActions';
import PageLoading from '../common/components/elements/PageLoading';
import {
  apiDownloadBrokerDocument, apiDownloadBrokerReport, apiDownloadBrokerBill, apiDownloadBrokerCompensationProof,
  apiDownloadPogDocument,
} from '../services/axios-client/axeCommons';
import SimpleModal from '../common/components/elements/SimpleModal';
import FileUpload from '../common/components/inputs/FileUpload';
import axe from '../services/axios-client/axiosClient';
import { generalAlertError, generalAlertSuccess } from '../common/utils/alerts';
import { BankTransferInfo, BankTransferInfoItem } from '../common/components/redesignComponents/Purchase/WaitingForPaymentStatus.styles';
import { Security } from './Security/Security';
import { UsefulDocuments } from './UsefulDocuments/UserfulDocuments';
import ErrorComponent from '../common/components/ErrorComponent';
// import { useTourContext } from '../context/useTourContext';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackProfile } from '../common/utils/joyrideCallbacks';
// import { profileSteps } from '../common/utils/joyrideSteps';

const Storage = appStorage();

function Profile() {
  const {
    broker_data: {
      loading, broker, documents, reports, bills, error, broker_agent_accepted_pogs,
    },
  } = useSelector((store) => store.user);
  const [compensationBillId, setCompensationBillId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('profilo') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('profilo'), loading]);

  // useEffect(() => {
  //   profileSteps(setSteps);
  // }, []);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('profilo');
  //   setState({ run: true, tourActive: true });
  // };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadBrokerDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadReport = async (documentId) => {
    const response = await apiDownloadBrokerReport(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadBill = async (compensation_bill_id) => {
    const response = await apiDownloadBrokerBill(compensation_bill_id);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadCompensationProof = async (compensation_proof_id) => {
    const response = await apiDownloadBrokerCompensationProof(compensation_proof_id);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    brokerData(Storage.getUser().id);
  }, []);

  const uploadCompensationBillProof = (file) => {
    if (compensationBillId) {
      const uploadedFile = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        axe.post(`/bills/${compensationBillId}/upload-compensation-proof`, {
          document: reader.result.replace(
            'data:application/pdf;base64,',
            '',
          ),
        })
          .then((res) => {
            brokerData(Storage.getUser().id);

            if (res.data.message === 'Success!') {
              setOpenModal(false);
              generalAlertSuccess(
                'Stiamo verificando la prova del pagamento, attendi nostro feedback!',
              );
            } else {
              setOpenModal(false);
              generalAlertError(res.data.error);
            }
          });
      };
    }
  };

  const getBillStatusFlag = (bill) => {
    let status = 'In Attesa Pagamento';
    if (bill.compensation_proof) {
      status = 'In Attesa verifica bonifico';
      if (bill.compensation_proof.compensation_proof_valid === 0) {
        status = 'Bonifico non valido';
      }
      if (bill.compensation_proof.compensation_proof_valid === 1) {
        status = 'Pagato';
      }
    }
    return status;
  };

  const downloadPogDocument = async (pog_id) => {
    const response = await apiDownloadPogDocument(pog_id);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.data.content;
    alink.download = response.data.data.data.filename;
    alink.click();
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <ProfileStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackProfile(data, steps, setState)}
        showSkipButton
        showProgress
      />
      {localStorage.getItem('profilo') === 'done' && (
      <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="mb-3 ">
        <button className="btn btn-success">Tutorial</button>
      </div>
      )} */}
      <div className="row">
        <div className="col-xl-12">
          <div className="card bg-light border-custom">
            <div className="card-body pt-3">
              <ul className="nav nav-tabs nav-tabs-bordered">
                <li className="nav-item">
                  <button className="nav-link active profilo-generale" data-bs-toggle="tab" data-bs-target="#profile-overview">Generale</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link profilo-miei-documenti" data-bs-toggle="tab" data-bs-target="#my-docs">Miei Documenti</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link profilo-estratti" data-bs-toggle="tab" data-bs-target="#reports">Estratti</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link profilo-estratti-compesazione" data-bs-toggle="tab" data-bs-target="#bills">Estratti Compensazione</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link profilo-sicurezza" data-bs-toggle="tab" data-bs-target="#security">Sicurezza</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link profilo-documenti-utili" data-bs-toggle="tab" data-bs-target="#useful-documents">Documenti Utili</button>
                </li>
                <li className="nav-item">
                  <button className="nav-link pog" data-bs-toggle="tab" data-bs-target="#pog">POGS</button>
                </li>
              </ul>
              <div className="tab-content pt-2">

                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                  <h2 className="card-title mt-3">
                    {broker?.name ? broker?.name : 'Nome non impostata'}
                    {' '}
                    {broker?.surname ? broker?.surname : 'Conome non impostata'}
                  </h2>
                  <h4 className="text-muted">Intermediario</h4>

                  <h5 className="card-title my-4">Detagli Utente</h5>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Nome</div>
                    <div className="col-8">{broker?.name ? broker?.name : 'Nome non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Cognome</div>
                    <div className="col-8">{broker?.surname ? broker?.surname : 'Cognome non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Eta</div>
                    <div className="col-8">{broker?.age ? broker?.age : 'Età non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Genere</div>
                    <div className="col-8">{broker?.gender ? broker?.gender : 'Gender non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Compagnia</div>
                    <div className="col-8">{broker?.broker_company ? broker?.broker_company?.name : 'Compagnia non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Telefono</div>
                    <div className="col-8">{broker?.phone ? broker?.phone : 'Telefono non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Email</div>
                    <div className="col-8">{broker?.email ? broker?.email : 'Email non impostata'}</div>
                  </div>

                  <div className="row my-3">
                    <div className="col-4 fw-semibold text-muted">Indirizzo</div>
                    <div className="col-8">{broker?.address ? broker?.address : 'Indirizzo non impostata'}</div>
                  </div>
                </div>
                <div className="tab-pane fade" id="my-docs">
                  <div className="px-3 pb-3">
                    <div className="row mt-2">
                      <h5 className="card-title mt-4 mb-2">Documenti Archiviati</h5>
                    </div>
                    {documents.length !== 0
                      ? (
                        <ol className="list-group list-group-numbered">
                          {documents.map((document, index) => (

                            <li className="list-group-item d-flex justify-content-between align-items-start" key={index}>
                              <div className="ms-2 me-auto">
                                <div className="fw-bold">{document.document_type.name}</div>
                                {document.original_filename}
                              </div>
                              <button className="btn btn-primary px-4 rounded-pill my-auto" onClick={() => downloadDocument(document.id)}>
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            </li>

                          ))}
                        </ol>
                      )
                      : <h6 className="d-flex justify-content-center">Non hai alcun file assegnato</h6>}
                  </div>
                </div>
                <div className="tab-pane fade" id="reports">
                  <div className=" pb-3">
                    <div className="row mt-2">
                      <h5 className="card-title mt-4 mb-2">Estratti Conto</h5>
                    </div>
                    {reports.length !== 0
                      ? (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Nome</th>
                              <th scope="col">Anno</th>
                              <th scope="col">Mese</th>
                              <th scope="col">Provvigioni</th>
                              <th scope="col">Brokeraggio</th>
                              <th scope="col">Azioni</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reports.map((report, index) => (
                              <tr key={index}>
                                <td className="my-auto">{report.report_name}</td>
                                <th>{report.year}</th>
                                <td>{report.month}</td>
                                <td>{report.amount}</td>
                                <td>{parseFloat(report.brokerage).toFixed(2)}</td>
                                <td>
                                  <button className="btn btn-primary btn-sm px-3 px-md-4 py-1 rounded-pill" onClick={() => downloadReport(report.document_id)}>
                                    <FontAwesomeIcon icon={faDownload} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                      : <h6 className="d-flex justify-content-center">Non hai alcun file assegnato</h6>}
                  </div>
                </div>

                <div className="tab-pane fade" id="bills">
                  <div className="pb-3">
                    <div className="row mt-2">
                      <h5 className="card-title mt-4 mb-2">Compensation Bill</h5>
                    </div>

                    {bills.length !== 0
                      ? (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Nome</th>
                              <th scope="col">Anno</th>
                              <th scope="col">Mese</th>
                              <th scope="col">Totale</th>
                              <th scope="col">Status</th>
                              <th scope="col">Azioni</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bills.map((bill, index) => (
                              <tr key={index}>
                                <td>{bill?.broker_compensation_bill?.original_filename}</td>
                                <th>{bill.year}</th>
                                <td>{bill.month}</td>
                                <td>{bill.amount}</td>
                                <td>{getBillStatusFlag(bill)}</td>
                                <td>
                                  <button className="btn btn-success btn-sm px-3 px-md-4 py-1 me-2 rounded-pill" onClick={() => downloadBill(bill.id)}>
                                    {/* <FontAwesomeIcon icon={faDownload} /> */}
                                    Scarica Estratto
                                  </button>
                                  <button className="btn btn-primary btn-sm px-3 px-md-4 rounded-pill me-2" onClick={() => { setCompensationBillId(bill.id); setOpenModal(true); }}>
                                    {/* <FontAwesomeIcon icon={faUpload} /> */}
                                    Carica Bonifico
                                  </button>
                                  {bill.compensation_proof
                                    && (
                                      <button className="btn btn-info btn-sm px-3 px-md-4 rounded-pill" onClick={() => { downloadCompensationProof(bill.compensation_proof.id); }}>
                                        {/* <FontAwesomeIcon icon={faUpload} /> */}
                                        Scarica Bonifico
                                      </button>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                      : <h6 className="d-flex justify-content-center">Non hai alcun file assegnato</h6>}
                  </div>
                  {openModal ? (
                    <SimpleModal setIsOpen={setOpenModal} title="Upload Bonifico">
                      <div>
                        <BankTransferInfo>
                          <BankTransferInfoItem>
                            Iban:
                            {' '}
                            <span>IT60B0326803210052864099270</span>
                          </BankTransferInfoItem>
                          <BankTransferInfoItem>
                            Titolare:
                            {' '}
                            <span>Greenia srl</span>
                          </BankTransferInfoItem>
                          <BankTransferInfoItem>
                            Causale:
                            {' '}
                            <span>
                              Pagamento Compensazione
                              {`${broker.name} ${broker.surname}`}
                            </span>
                          </BankTransferInfoItem>
                        </BankTransferInfo>
                        <FileUpload
                          multiple={false}
                          label="CARICA PROVA DEL PAGAMENTO"
                          handleFile={(file) => uploadCompensationBillProof(file)}
                        />
                      </div>
                    </SimpleModal>
                  )
                    : ''}
                </div>

                <div className="tab-pane fade" id="security">
                  <Security />
                </div>

                <div className="tab-pane fade" id="useful-documents">
                  <UsefulDocuments />
                </div>
                <div className="tab-pane fade" id="pog">
                  <div className="pb-3">
                    {broker_agent_accepted_pogs.length !== 0
                      ? (
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Titolo</th>
                              <th scope="col">Descrizione</th>
                              <th scope="col">Accettato</th>
                              <th scope="col">Accettato il</th>
                              <th scope="col">Azioni</th>
                            </tr>
                          </thead>
                          <tbody>
                            {broker_agent_accepted_pogs.map((broker_agent_accepted_pog, index) => (
                              <tr key={index}>
                                <td>{broker_agent_accepted_pog?.pog?.title}</td>
                                <td>{broker_agent_accepted_pog?.pog?.description}</td>
                                <th>{broker_agent_accepted_pog.is_accepted ? 'Accettato' : 'Non Accettato'}</th>
                                <td>{moment(broker_agent_accepted_pog.accepted_at).format('DD/MM/YYYY HH:mm')}</td>
                                <td>
                                  <button className="btn btn-success btn-sm px-3 px-md-4 py-1 me-2 rounded-pill" onClick={() => downloadPogDocument(broker_agent_accepted_pog.pog_id)}>
                                    {/* <FontAwesomeIcon icon={faDownload} /> */}
                                    Scarica Documenti
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                      : <h6 className="d-flex justify-content-center">Non hai alcun file assegnato</h6>}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ProfileStyles>
  );
}

export default Profile;
