import React, { useRef, useState } from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol,
  LogoContainer,
  LogoPriceContainer,
  Price,
  PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';
import {
  updateGuaranteesAutoAction,
  updateGuaranteesMotoAction,
} from '../../../../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert } from '../../../utils/alerts';
// import SparklesIcon from '../../../../assets/images/icons/SparklesIcon'
import { Chat } from '../../chats/MiniChat/MiniChat';

function RiskCardPrima({
  quote,
  type,
  requestInfo,
  processingQuote,
  closeButton,
}) {
  const [modalCoverages, setModalCoverages] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();

  const addGuarantees = (coverages) => {
    if (processingQuote.product_id === 2) {
      updateGuaranteesMotoAction(
        requestInfo.request_token,
        coverages,
        processingQuote.id,
      );
    } else {
      updateGuaranteesAutoAction(
        requestInfo.request_token,
        coverages,
        processingQuote.id,
      );
    }
    closeButton();
  };

  const updateCoverages = (coverageSlug) => {
    const cov = processingQuote.guarantees.find(
      (c) => c.guarantees_slug === coverageSlug,
    );
    const coverages = [];
    if (cov === undefined) {
      processingQuote.guarantees.map((c) => coverages.push(c.guarantees_slug));
      if (coverageSlug === 'eventi_naturali') {
        coverages.push('eventi_sociopolitici');
        const hasFurtoIncendio = coverages.find((c) => c === 'furto_incendio');
        if (hasFurtoIncendio === undefined) {
          coverages.push('furto_incendio');
        }
      }
      if (coverageSlug === 'eventi_sociopolitici') {
        coverages.push('eventi_naturali');
        const hasFurtoIncendio = coverages.find((c) => c === 'furto_incendio');
        if (hasFurtoIncendio === undefined) {
          coverages.push('furto_incendio');
        }
      }
      coverages.push(coverageSlug);
    } else {
      setModalCoverages(modalCoverages.filter((c) => c !== coverageSlug));
      processingQuote.guarantees
        .filter((cv) => cv.guarantees_slug !== coverageSlug)
        .map((c) => coverages.push(c.guarantees_slug));

      if (coverageSlug === 'eventi_naturali') {
        setModalCoverages(
          modalCoverages.filter((c) => c !== 'eventi_sociopolitici'),
        );
        processingQuote.guarantees
          .filter((cv) => cv.guarantees_slug !== 'eventi_sociopolitici')
          .map((c) => coverages.push(c.guarantees_slug));
      }
      if (coverageSlug === 'eventi_sociopolitici') {
        setModalCoverages(modalCoverages.filter((c) => c !== 'eventi_naturali'));
        processingQuote.guarantees
          .filter((cv) => cv.guarantees_slug !== 'eventi_naturali')
          .map((c) => coverages.push(c.guarantees_slug));
      }
    }

    // TODO ADD IF REQUIRED!!!!
    // if (coverageSlug === 'cristalli') {
    //   if (processingQuote.guarantees.find((cv) => cv.guarantees_slug === 'furto_incendio') === undefined) {
    //     coverages.push('furto_incendio');
    //   }
    // }

    addGuarantees(coverages);
  };

  const addNewGuarantee = (coverageSlug) => {
    confirmAlert('Sei Sicuro Di Aggiungere Questo Rischio?', () => updateCoverages(coverageSlug));
  };

  // const includesCoverage = (key) => modalCoverages !== null && modalCoverages.includes(key);
  const extraText = `Limit: ${quote.limits_name}`;

  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      {/* <button
        onClick={() => setOpenChat(true)}
        className="open-chat-button border-0  text-white bg-success rounded p-1"
        style={{ position: 'absolute', right: '30px', top: '30px' }}
      >
        <SparklesIcon width={40} height={40} />
      </button> */}
      <Chat
        ref={chatRef}
        openChat={openChat}
        setOpenChat={setOpenChat}
        company_id={processingQuote.company_id}
        product_id={processingQuote.product_id}
        quote_type={quote.slug}
        company_name={processingQuote.company.name}
        extraText={extraText}
      />
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img
                src={BACKEND_BASE_URL + processingQuote.company.logo}
                alt={processingQuote.company.name}
              />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>Prezzo annuo:</h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(
                    quote.quotation_data_prima_guarantee_prices[0].discounted,
                  ).toFixed(2)}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{processingQuote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Limit:</h6>
              <p>{quote.limits_name}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {type.id === 'assistenza_stradale' && (
              <>
                {requestInfo.assistenza_stradale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.assistenza_stradale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'cristalli' && (
              <>
                {requestInfo.cristalli_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.cristalli_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'infortuni_conducente' && (
              <>
                {requestInfo.infortuni_conducente_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.infortuni_conducente_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'protezione_rivalse' && (
              <>
                {requestInfo.rivalsa_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee('protezione_rivalse')}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.rivalsa_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'tutela_legale' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'kasko' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'collisione' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'eventi_sociopolitici' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'eventi_naturali' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'bonus_protetto' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
            {type.id === 'furto_incendio' && (
              <>
                {requestInfo.tutela_legale_quote_id !== quote.id ? (
                  <AquistaButton
                    onClick={() => addNewGuarantee(quote.slug)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : (
                  requestInfo.tutela_legale_quote_id === quote.id && (
                    <SavedButton>Aggiunto</SavedButton>
                  )
                )}
              </>
            )}
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCardPrima;
