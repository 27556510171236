import React, { useRef, useState } from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  LeftCol,
  LogoPriceContainer,
  PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import {
  LogoResultContainer,
  PriceResult,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
// import SparklesIcon from '../../../../assets/images/icons/SparklesIcon'
import { Chat } from '../../chats/MiniChat/MiniChat';

function RiskCardPrimaResult({ quote, processingQuote }) {
  // const includesCoverage = (key) => modalCoverages !== null && modalCoverages.includes(key);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();
  const extraText = `Limit: ${quote.limits_name}`;

  return (
    <ProfessionCardStyles className="selected">
      {/* <button
        onClick={() => setOpenChat(true)}
        className="open-chat-button border-0  text-white bg-success rounded p-1"
        style={{ position: 'absolute', right: '30px', top: '30px' }}
      >
        <SparklesIcon width={40} height={40} />
      </button> */}
      <Chat
        ref={chatRef}
        openChat={openChat}
        setOpenChat={setOpenChat}
        company_id={processingQuote.company_id}
        product_id={processingQuote.product_id}
        quote_type={quote.quote_type}
        company_name={processingQuote.company.name}
        extraText={extraText}
      />
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img
                src={BACKEND_BASE_URL + processingQuote.company.logo}
                alt={processingQuote.company.name}
              />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>Prezzo annuo:</h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(
                    quote.quotation_data_prima_guarantee_prices[0].discounted,
                  ).toFixed(2)}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>{processingQuote.company.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Prodotto:</h6>
              <p>{quote.name}</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Limit:</h6>
              <p>{quote.limits_name}</p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol />
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCardPrimaResult;
