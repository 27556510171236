import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  addBackOfficeQuoteToCart,
  loadBackOfficeQuotes,
  removeQuoteMotoBackofficeFromCart,
  resetBackOfficeQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import { confirmAlert } from '../../../common/utils/alerts';
import { Layout } from '../components/Layout/Layout';
import { TravelQuotesStyles } from './TravelResultPage.styles';
import TravelCardInfo from './cards/TravelCardInfo';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import { GuaranteesDefaultStateStyles } from '../components/common/Modal/GuaranteesDefaultStateTravel.styles';
import { quotationTypes } from '../../../common/constants';
import {
  RespCivileModal,
  RiskButton,
  RiskButtonRemove,
  RiskCard,
  RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import EuropeassistanceCard from '../../../common/components/redesignComponents/Cards/EuropeassistanceCard';
import ProcessingQuoteSelectedTravel from '../../../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedTravel';

function BackOfficeTravelQuotes() {
  const { requestToken } = useParams();
  const state = useSelector(
    (store) => store.backofficeResults.back_office_quotes,
  );
  const {
    loading,
    requestInfo,
    quotes,
    failedQuotes,
    processingQuote,
    addingQuote,
    customer,
    risk_quotations,
  } = state;

  const navigate = useNavigate();

  useEffect(() => {
    loadBackOfficeQuotes(requestToken);

    return () => {
      resetBackOfficeQuotes();
    };
  }, []);

  const modifyData = () => {
    navigate(`/backoffice/quote/${requestToken}/travel`);
  };

  const fetchQuotes = () => {
    loadBackOfficeQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addBackOfficeQuoteToCart(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteMotoBackofficeFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let risksTotalAmount = 0;

    if (processingQuote !== null && processingQuote.amount !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }

    const riskTypes = quotationTypes.filter((t) => t.id !== 'res_civile');

    for (let x = 0; x < riskTypes.length; x++) {
      const type = riskTypes[x];
      if (risk_quotations && risk_quotations[type.id] !== undefined) {
        for (let i = 0; i < risk_quotations[type.id].length; i++) {
          const item = risk_quotations[type.id][i];

          risksTotalAmount += parseFloat(item.amount);
        }
      }
    }

    return (quoteInProccessAmount + risksTotalAmount).toFixed(2);
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <div
        className="resultsPage"
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: 'auto',
          padding: '10px',
        }}
      >
        <TravelQuotesStyles>
          <div className="resultsPage">
            <div className="row">
              <div className="col-12 col-xxl-4 mb-2 mb-xxl-0">
                <TravelCardInfo
                  insuranceRequest={requestInfo}
                  customer={customer}
                  modificaDati={modifyData}
                />
              </div>
              <div className="col-12 col-xxl-8">
                {/* <GuaranteesDefaultStateTravel /> */}
                <GuaranteesDefaultStateStyles>
                  <div className="container mt-4">
                    {processingQuote === null ? (
                      <h5 className="fw-bold">
                        Seleziona Le Quotazioni Che Vuoi Aquistare
                      </h5>
                    ) : (
                      <>
                        <ul className="list-unstyled">
                          {processingQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {processingQuote.company !== null
                                    ? processingQuote.company.name
                                    : processingQuote.preventivas_company
                                      .company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {
                                      quotationTypes.find(
                                        (item) => item.id === processingQuote.quote_type,
                                      ).name
                                    }
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(processingQuote.amount).toFixed(
                                    2,
                                  )}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {quotationTypes
                            .filter((t) => t.id !== 'res_civile')
                            .map((type, index) => (
                              <React.Fragment key={index}>
                                {risk_quotations && risk_quotations[type.id]
                                  ? risk_quotations[type.id].map((quote, i) => (
                                    <li key={i}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5>
                                          {quote.company !== null
                                            ? quote.company.name
                                            : quote.preventivas_company
                                              .company_name}
                                          {' '}
                                          <span className="text-success">
                                            (
                                            {
                                                quotationTypes.find(
                                                  (item) => item.id === quote.quote_type,
                                                ).name
                                              }
                                            )
                                          </span>
                                        </h5>
                                        <h5 className="fw-bold">
                                          {parseFloat(quote.amount).toFixed(
                                            2,
                                          )}
                                          {' '}
                                          €
                                        </h5>
                                      </div>
                                    </li>
                                  ))
                                  : ''}
                              </React.Fragment>
                            ))}
                          <hr />
                          <li>
                            <div className="d-flex justify-content-end align-items-center">
                              <h5 className="me-5 fw-bold">Totale:</h5>
                              <h5 className="fw-bold">
                                {' '}
                                {getTotal()}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </GuaranteesDefaultStateStyles>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <h4>
                    Preventivi assicurazione
                    {' '}
                    <b>viaggi</b>
                  </h4>
                  <p>
                    Inizio Copertura:
                    {' '}
                    {new Date(
                      requestInfo.policy_effective_date,
                    ).toLocaleDateString('it-IT', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })}
                  </p>
                </EstimateContainer>
              </div>
            </div>
            <RiskContainer className="mb-4">
              {[
                {
                  id: 'res_civile',
                  name: 'Responsabilità Civile',
                },
              ].map((type, index) => (
                <React.Fragment key={index}>
                  {quotes && quotes[type.id] && (
                    <RiskCard
                      className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${
                        requestInfo.processing_quotation_id === null
                        && type.id !== 'res_civile'
                          ? 'bg-secondary bg-opacity-25'
                          : ''
                      } ${
                        requestInfo.processing_quotation_id !== null
                        && type.id === 'res_civile'
                          ? 'bg-success bg-opacity-25'
                          : ''
                      } ${
                        risk_quotations
                        && risk_quotations[type.id] !== undefined
                          ? 'bg-success bg-opacity-25'
                          : ''
                      }`}
                    >
                      <div className="p-3 me-auto">
                        <h5>
                          {type.id == 'res_civile' ? 'Viaggi' : type.name}
                        </h5>
                      </div>
                      {processingQuote !== null && type.id === 'res_civile' && (
                        <ProcessingQuoteSelectedTravel
                          processingQuote={processingQuote}
                          requestInfo={requestInfo}
                        />
                      )}

                      <div className="d-flex justify-content-end ms-auto gap-2">
                        {type.id === 'res_civile'
                          && processingQuote !== null && (
                            <RiskButtonRemove
                              className="btn btn-danger"
                              onClick={() => removeItemFromCart(processingQuote.id)}
                            >
                              Rimuovi
                            </RiskButtonRemove>
                        )}

                        {type.id === 'res_civile' && (
                          <RiskButton
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target={`#${type.id}`}
                          >
                            Aggiungi
                          </RiskButton>
                        )}
                      </div>
                    </RiskCard>
                  )}
                </React.Fragment>
              ))}
            </RiskContainer>

            {[
              {
                id: 'res_civile',
                name: 'Responsabilità Civile',
              },
            ].map((type, index) => (
              <RespCivileModal
                className="modal fade"
                id={type.id}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                key={index}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        <h5>
                          {type.id == 'res_civile' ? 'Viaggi' : type.name}
                        </h5>
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="buttonClose"
                      />
                    </div>
                    <div className="modal-body">
                      {addingQuote && (
                        <div className="d-flex align-items-center justify-content-between flex-column">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Aggiungendo...
                              </span>
                            </div>
                          </div>
                          <div className="ms-2">Aggiungendo...</div>
                        </div>
                      )}
                      {addingQuote === false && (
                        <div className="row purchase-quote-eight-step p-1">
                          {addingQuote === false
                          && quotes
                          && quotes[type.id] ? (
                              quotes[type.id].map((quote, index) => (
                                <>
                                  <EuropeassistanceCard
                                    quote={quote}
                                    requestToken={requestToken}
                                    guarantees={quote.quotation_coverages}
                                    insuranceRequest={requestInfo}
                                    key={index}
                                    selectQuotation={selectQuotation}
                                    fetchData={fetchQuotes}
                                  />
                                </>
                              ))
                            ) : (
                              <h5 className="fw-bold">
                                Nessuna Quotazione Trovata
                              </h5>
                            )}
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger px-4"
                        data-bs-dismiss="modal"
                      >
                        Chiudi
                      </button>
                    </div>
                  </div>
                </div>
              </RespCivileModal>
            ))}
          </div>
        </TravelQuotesStyles>
        {failedQuotes.length !== 0 && (
          <div className="failed-quotations-block">
            Queste compagnie non hanno risposto con una citazione.
            <br />
            <hr />
            <div className="container ms-0">
              <div className="row">
                {failedQuotes.map((failed, index) => (
                  <div className="col-3" key={index}>
                    <img
                      src={BACKEND_BASE_URL + failed.company.logo}
                      alt={`${failed.company.name} Logo`}
                      width={100}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default BackOfficeTravelQuotes;
