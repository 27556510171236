import React, { useState } from 'react';
import {
  CompanyInfoElements,
  CompanyInformationContainer,
} from '../QuotationCard.styles';
import AdriaticInfoModal from '../../components/redesignComponents/Modal/AdriaticInfoModal';
import infoIcon from '../../../assets/redesign_icons/info-icon.svg';
import preventiveIcon from '../../../assets/redesign_icons/preventivo-icon.svg';
import preContractIcon from '../../../assets/redesign_icons/pre-contract-icon.svg';

import {
  apiDownloadManuallyAddedQuotationDocument,
  downloadResourceDocs,
} from '../../../services/axios-client/axeCommons';
import PrimaInfoModal from '../../components/redesignComponents/Modal/PrimaInfoModal';
import ProfessionInfoModal from '../../components/redesignComponents/Modal/ProfessionInfoModal';
import TutelaLegaleInfoModal from '../../components/redesignComponents/Modal/TutelaLegaleInfoModal';
import SaluteInfoModal from '../../components/redesignComponents/Modal/SaluteInfoModal';
import CasaInfoModal from '../../components/redesignComponents/Modal/CasaInfoModal';
import AncillariInfoModal from '../../components/redesignComponents/Modal/AncillariInfoModal';

function CompanyInfoComponent({
  quote,
  guaranteesPrima,
  set_informativi,
  docs,
  extensions,
  requestToken,
  selectableExtensions,
  profession,
}) {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(!showModal);
  };

  const downloadDocument = async (document_id) => {
    const response = await apiDownloadManuallyAddedQuotationDocument(
      document_id,
    );
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.data.contents;
    alink.download = response.data.data.data.filename;
    alink.click();
  };

  return (
    <>
      {showModal && (quote.company_id === 1 || quote.company_id === 17) && (
        <AdriaticInfoModal quotation={quote} setShowModal={setShowModal} />
      )}
      {showModal && quote.company_id === 2 && (
        <PrimaInfoModal
          setShowModal={setShowModal}
          quote={quote}
          guaranteesPrima={guaranteesPrima}
        />
      )}
      {showModal && quote.company_id === 4 && (
        <ProfessionInfoModal
          userData={quote}
          setShowModal={setShowModal}
          docs={docs}
          set_informativi={set_informativi}
          extensions={extensions}
          requestToken={requestToken}
          selectableExtensions={selectableExtensions}
          profession={profession}
        />
      )}
      {showModal && quote.company_id === 15 && (
        <TutelaLegaleInfoModal quotation={quote} setShowModal={setShowModal} />
      )}

      {showModal && (quote.company_id === 8 && quote.product_id === 12) && (
      <SaluteInfoModal setShowModal={setShowModal} quote={quote} />
      )}
      {showModal && ((quote.company_id === 8 || quote.company_id === 16) && quote.product_id === 13) && (
        <AncillariInfoModal setShowModal={setShowModal} quote={quote} />
      )}
      {showModal && (quote.company_id == 8 && quote.product_id == 11) && (
      <CasaInfoModal setShowModal={setShowModal} quote={quote} />
      )}
      {(quote.company_id === 1
        || quote.company_id === 17
        || quote.company_id === 2) && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModal()}>
            <img src={infoIcon} alt="" />
            <p>Maggiori informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}

      {quote.company_id === 8 && quote.product_id === 12 && (
      <CompanyInformationContainer>
        <CompanyInfoElements onClick={() => openModal()}>
          <img src={infoIcon} alt="" />
          <p>Maggiori informazioni</p>
        </CompanyInfoElements>
      </CompanyInformationContainer>
      )}

      {quote.company_id === 8 && quote.product_id === 11 && (
      <CompanyInformationContainer>
        <CompanyInfoElements onClick={() => openModal()}>
          <img src={infoIcon} alt="" />
          <p>Maggiori informazioni</p>
        </CompanyInfoElements>
      </CompanyInformationContainer>
      )}

      { quote.product_id === 13 && (
      <CompanyInformationContainer>
        <CompanyInfoElements onClick={() => openModal()}>
          <img src={infoIcon} alt="" />
          <p>Maggiori informazioni</p>
        </CompanyInfoElements>
      </CompanyInformationContainer>
      )}

      {quote.manually_added_quotation_documents
        && quote.manually_added_quotation_documents.length > 0 && (
          <CompanyInformationContainer>
            {quote.manually_added_quotation_documents.map((doc) => (
              <CompanyInfoElements onClick={() => downloadDocument(doc.id)}>
                <img
                  src={
                    doc.type === 'preventive' ? preventiveIcon : preContractIcon
                  }
                  alt=""
                />
                <p>
                  {doc.type === 'preventive'
                    ? 'Preventivo'
                    : 'Informazioni Precontrattuali'}
                </p>
              </CompanyInfoElements>
            ))}
          </CompanyInformationContainer>
      )}
      {quote.company_id === 4 && (
        <>
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Maggiori informazioni</p>
            </CompanyInfoElements>
            {docs[profession.profession] !== '' && (
              <CompanyInfoElements
                onClick={() => downloadResourceDocs(4, profession.profession)}
                title={`${docs[profession.profession]}`}
              >
                <img src={preventiveIcon} alt="" />
                <p>Questionario</p>
              </CompanyInfoElements>
            )}
            {set_informativi[profession.profession] !== '' && (
              <CompanyInfoElements
                onClick={() => downloadResourceDocs(8, profession.profession)}
                title={`${set_informativi[profession.profession]}`}
              >
                <img src={preContractIcon} alt="" />
                <p>Informativa Precontrattuale</p>
              </CompanyInfoElements>
            )}
          </CompanyInformationContainer>
        </>
      )}

      {quote.company_id === 15 && quote.information && (
        <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => setShowModal(!showModal)}>
            <img src={infoIcon} alt="" />
            <p>Maggiori informazioni</p>
          </CompanyInfoElements>
        </CompanyInformationContainer>
      )}
    </>
  );
}

export default CompanyInfoComponent;
