import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AncillariInfoModalStyles, LeftSide, ModalBody, ModalHeader,
  TextContainer,
  TitleBox,
} from './AncillariInfoModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import { ModalContent } from './GlobalAssistencaStradaleModal.styles';
import { ancillariInfo } from '../../../constants';

function AncillariInfoModal({ setShowModal, quote }) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <AncillariInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          {/* <RightSide>
          {docs[userData.profession] !== '' && (
          <CompanyInfoElements
            onClick={() => downloadResourceDocs(4, userData.profession)}
            title={`${docs[userData.profession]}`}
          >
            <img src={preventiveIcon} alt="" />
            <p>Questionario</p>
          </CompanyInfoElements>
          )}
          {set_informativi[userData.profession] !== '' && (
          <CompanyInfoElements
            onClick={() => downloadResourceDocs(8, userData.profession)}
            title={`${set_informativi[userData.profession]}`}
          >
            <img src={preContractIcon} alt="" />
            <p>Informativa Precontrattuale</p>
          </CompanyInfoElements>
          )}
        </RightSide> */}
        </ModalHeader>
        <ModalContent>
          <div className="card-extra-information">
            <h3 className="mb-3">Informazioni</h3>
            {ancillariInfo.map((info) => (
              quote.quote_type === info.quote_type && quote.company_id == info.company_id && (
              <>
                <h5 className="mb-3">{info.firstHeader}</h5>
                <p>{info.desc}</p>

                {info.limits.length > 0 && (
                <ul>
                  {info.limits.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                )}

                <h5 className=" my-3">{info.secondHeader}</h5>
                <p>{info.secDesc}</p>

                {info.secondLimits.length > 0 && (
                <ul>
                  {info.secondLimits.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                )}
                {info.subText && (
                <p className="mt-3">{info.subText}</p>
                )}
                {/* {info.extra && (
                <p className="mt-4">
                  <b>Estensione valida per tutte le Garanzie: </b>
                  {' '}
                  {info.extra}
                </p>
                )} */}
              </>

              )
            ))}

          </div>
        </ModalContent>
      </ModalBody>
    </AncillariInfoModalStyles>
  );
}

export default AncillariInfoModal;
