import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  // ? What we need
  notificationsCount: 0,
  messagesCount: 0,
  notifications: [],
  loading: false,
  error: null,
  total_pages: 1,
  page_number: 1,
  unaccepted_pogs: [],
};

export const utilitiesSlice = createSlice({
  name: 'utilities',
  initialState,
  reducers: {
    // ? Unread Notifications
    notificationsSuccess: (state, { payload }) => {
      state.notificationsCount = payload.data.count;
      state.messagesCount = payload.data.totalUnreadMessages;
    },
    // ? Unread Messages
    messagesUnreadSuccess: (state, { payload }) => {
      state.messagesCount = payload.data;
    },
    // ? Get All Notifications
    getNotificationsPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    getNotificationsRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getNotificationsSuccess: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload.data.notifications;
      state.notificationsCount = payload.data.count;
      state.total_pages = payload.data.total_pages;
      state.page_number = payload.data.current_page;
    },
    getNotificationsReset: (state) => {
      state.loading = false;
      state.notifications = [];
      state.error = null;
    },
    getUnacceptedPogsSuccess: (state, { payload }) => {
      state.unaccepted_pogs = payload.data.pogs;
    },
  },
});

export default callerCreator(utilitiesSlice.actions);
