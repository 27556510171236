import React, { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
// import Joyride from 'react-joyride';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { storeSectionEBroker, storeSectionEBrokerReset } from '../../features/brokers/brokersActions';
import LoadingGif from '../../assets/images/loading.gif';
import { invoiceTypes, genders } from '../../common/constants';
import { MapContainerStyles, NewBrokerStyles } from './NewBroker.styles';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { Label } from '../../common/formInputs/styles/common';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackNewBrokers } from '../../common/utils/joyrideCallbacks';
// import { newBrokerSteps } from '../../common/utils/joyrideSteps';

const schema = yup.object({
  name: yup
    .string()
    .required('Il campo è obbligatorio')
    .min(3, 'Il campo deve contenere almeno 3 caratteri')
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.max(50)
      : schema.max(191))),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio')
        .min(3, 'Il campo deve contenere almeno 3 caratteri')
        .max(191)
      : schema)),
  email: yup.string()
    .required('Il campo è obbligatorio')
    .matches(/^[a-zA-Z0-9.@]+$/, 'Sono ammessi solo lettere (a-z), numeri (0-9), punti (.) e (@).')
    .email('Email deve essere valida'),
  password: yup.string()
    .required('Il campo è obbligatorio')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,32}$/,
      'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale',
    ),
  invoice_type: yup.string()
    .required('Devi specificare la tipologia di ritenuta'),
  phone: yup.string().required('Il campo è obbligatorio').max(255),
  pec: yup.string().required('Il campo è obbligatorio').max(191),
  ivass_registration_number: yup.string().required('Il campo è obbligatorio').max(255),
  city: yup.string().required('Il campo è obbligatorio').max(191),
  vat_number: yup.string().required('Il campo è obbligatorio').matches(/^[0-9]{11}$/, 'Il codice fiscale non è valido'),
  comission: yup.number().typeError('Il campo deve essere un numero').required('Il campo è obbligatorio').min(0)
    .max(100),
  age: yup.number().integer('Il campo deve essere un numero').typeError('Il campo deve essere un numero')
    .required('Il campo è obbligatorio')
    .min(0)
    .max(200),
  gender: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),
});

function NewBroker() {
  const navigate = useNavigate();

  const { loading } = useSelector((store) => store.brokers.create_broker_section_e);

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      name: '',
      surname: '',
      phone: '',
      email: '',
      pec: '',
      city: '',
      age: '',
      password: '',
      ivass_registration_number: '',
      vat_number: '',
      comission: '',
      gender: '',
      invoice_type: '',
      address: '',
      latitude: 41.902782,
      longitude: 12.496366,
    },
  });

  const answers = watch();

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load()
      .then((google) => {
        const myLatLng = {
          lat: answers.latitude,
          lng: answers.longitude,
        };

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 15,
        });

        new google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!',
        });

        const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          fields: ['formatted_address', 'geometry', 'name'],
          types: ['address'],
          componentRestrictions: { country: 'it' },
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (!place.geometry) {
            setValue('address', '');
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 15,
              center: place.geometry.location,
            });

            new google.maps.Marker({
              position: place.geometry.location,
              map,
            });

            setValue('address', place.formatted_address);
            setValue('latitude', place.geometry.location.lat());
            setValue('longitude', place.geometry.location.lng());
          }
        });
      });

    return () => {
      if (loader) {
        loader.reset();
        delete window.google;
        Loader.instance = undefined;
      }
    };
  }, []);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitData = (data) => {
    storeSectionEBroker(data, () => {
      navigate('/brokers');
    });
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('createBroker') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('createBroker')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('createBroker');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => () => {
    storeSectionEBrokerReset();
  }, []);

  return (
    <NewBrokerStyles>
      {/* <Joyride
        steps={newBrokerSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackNewBrokers(data, newBrokerSteps, setState)}
        showSkipButton
        showProgress
      />
      <div className="d-flex justify-content-end">
        {localStorage.getItem('createBroker') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
            <button className="btn btn-success text-white px-4 ">Tutorial</button>
          </div>
        )}
      </div> */}

      <h1 className="screen-title">Nuovo Intermediario</h1>

      <form onSubmit={handleSubmit(submitData)}>
        <div className="create-broker-one">

          <div className="row ">
            <div className="col-md-12 col-lg-12">
              <FormInputRadio
                name="gender"
                label="Genere *"
                options={genders}
                registration={register('gender')}
                error={errors.gender?.message}
                valid={isValid('gender')}
              />
            </div>
          </div>

          {answers.gender !== 'G' && (
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <FormInputText
                  label="Nome *"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <FormInputText
                  label="Cognome *"
                  name="surname"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
              </div>
            </div>
          )}

          {answers.gender === 'G' && (
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <FormInputText
                  label="Ragione Sociale *"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Telefono *"
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Email *"
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Pec *"
                registration={register('pec')}
                error={errors.pec?.message}
                valid={isValid('pec')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Citta *"
                registration={register('city')}
                error={errors.city?.message}
                valid={isValid('city')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Eta *"
                registration={register('age')}
                error={errors.age?.message}
                valid={isValid('age')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Password *"
                registration={register('password')}
                error={errors.password?.message}
                valid={isValid('password')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Numero di iscrizione Ivass *"
                registration={register('ivass_registration_number')}
                error={errors.ivass_registration_number?.message}
                valid={isValid('ivass_registration_number')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Partita Iva *"
                registration={register('vat_number')}
                error={errors.vat_number?.message}
                valid={isValid('vat_number')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-6">
              <FormInputText
                label="Comissione (%) *"
                registration={register('comission')}
                error={errors.comission?.message}
                valid={isValid('comission')}
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <FormInputSelect
                label="Tipologia ritenuta d'acconto *"
                name="invoice_type"
                options={invoiceTypes}
                placeholder=" - Seleziona - "
                registration={register('invoice_type')}
                error={errors.invoice_type?.message}
                valid={isValid('invoice_type')}
              />
            </div>
          </div>
        </div>

        <br />

        <div className="row create-broker-two">
          <h3>Detagli</h3>
          <Label>Indirizzo</Label>
          <input
            name="address"
            id="autocomplete"
            placeholder="Inserisci Indirizzo..."
            className={`form-control ${errors.address?.message ? 'is-invalid' : ''}`}
            {...register('address')}
          />
          <div className="invalid-feedback">
            {errors.address?.message}
          </div>
          <MapContainerStyles>
            <div id="map" />
          </MapContainerStyles>
        </div>

        {loading === true
          && (
            <div className="text-center">
              <img className="loading-gif" src={LoadingGif} />
              <br />
              Attendere prego
            </div>
          )}
        {loading === false
          && <button className="submit-button create-broker-three" type="submit">Crea Intermediario</button>}
      </form>

    </NewBrokerStyles>
  );
}

export default NewBroker;
