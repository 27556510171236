import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { get, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CommercialQuestionnaireStyles, SkipQuestionario, SkipQuestionarioStyles } from './CommercialQuestionnaireStyles.styles';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';
import {
  getCommercialBranches,
  resetQuestionnaireModifyData,
  // getQuestionarioStatus,
  resetQuestionnaireRequestToken, saveQuestionario, skipQuestionario,
} from '../../features/commercialReport/commercialReportActions';
// import PageLoading from '../../common/components/elements/PageLoading';
import { confirmAlert, generalAlertErrorToast } from '../../common/utils/alerts';
import { validationsSchema } from './commercialQuestionario.validation';
import LocationAndAmbient from './Questionario/components/LocationAndAmbient';
import StructureEdilizia from './Questionario/components/StructureEdilizia';
import InfrastructureImpianti from './Questionario/components/InfrastructureImpianti';
import MacchinariEAttrezature from './Questionario/components/MacchinariEAttrezature';
import SicurezzaEPrevenzione from './Questionario/components/SicurezzaEPrevenzione';
import LogisticaTrasporti from './Questionario/components/LogisticaTrasporti';
import ProcessProdotti from './Questionario/components/ProcessProdotti';
import ContinuitaIt from './Questionario/components/ContinuitaIt';
import FinanzaCrediti from './Questionario/components/FinanzaCrediti';
import PersonaleRuoliChiave from './Questionario/components/PersonaleRuoliChiave';
import { CommercialQuoteLoading } from '../../common/components/CommercialQuoteLoading';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../common/constants';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
// import { CommercialQuoteLoading } from '../../common/components/CommercialQuoteLoading';

function CommercialQuestionnaire() {
  const [skip, setSkip] = useState(false);

  const {
    // loading, company,
    request_token, generatingQuotes, old_request_token, modify_data, errorMessage, company_branches,
  } = useSelector(
    (store) => store.commercialReport.questionnaire,
  );
  const { company_id } = useParams();
  const navigate = useNavigate();
  const [extraData, setExtraData] = useState({
    extra_quotations: null,
    company_branch_id: '',
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationsSchema),
    shouldFocusError: true,
    defaultValues: {
      location_and_structures: {
        proximity_to_water_bodies: null,
        proximity_to_fragile_slopes_or_landslide_areas: null,
        elevation_above_ground_level: null,
        proximity_to_major_transport_routes: null,
        presence_of_groundwater: null,
        presence_of_basements_or_semi_basements: null,
        presence_of_shelving: null,
        non_seismic_roof_configuration: null,
        beams_anchored_to_pillars: null,
        presence_of_electrical_transformer_rooms: null,
        presence_of_thermal_power_station: null,
        presence_of_air_compressors: null,
        presence_of_refrigeration_units: null,
        combustible_building_materials: null,
        presence_of_confined_spaces: null,
        data_processing_equipment_in_basement: null,
        presence_of_classic_sandwich_panels: null,
        roof_supporting_structure_material: '',
      },
      infrastructure_and_machines: {
        sewer_level_above_public_sewers: null,
        electrical_system_pre_1990: null,
        damage_risk_to_equipment_due_to_power_outage: null,
        damage_risk_to_computers_due_to_power_outage: null,
        it_system_essential_for_continuity: null,
        presence_of_combustible_packaging: null,
        presence_of_expanded_plastic_packaging: null,
        use_of_flammable_gases: null,
        staff_present_during_closure: null,
        explosion_risk: null,
        firefighter_response_time_under_15_minutes: null,
        staff_present_at_night: null,
        valuable_goods_storage: null,
        use_of_forklifts_or_load_handling_systems: null,
        risk_of_exposure_to_carcinogens: null,
        use_of_chemicals: null,
        presence_of_underground_tanks_with_non_water_substances: null,
        machinery_using_oil: null,
        concrete_or_resin_flooring: null,
        use_or_storage_of_flammable_solvents: null,
        use_of_open_flames: null,
        goods_sensitive_to_humidity: null,
        machinery_sensitive_to_humidity: null,
        presence_of_structures_like_chimneys_towers_silos_tanks: null,
        use_of_unique_machinery_for_production: null,
        use_of_custom_machinery: null,
        machinery_no_longer_in_production: null,
        possibility_to_modify_process_with_alternative_machinery: null,
        machinery_exposed_to_corrosive_environments: null,
        machinery_in_dusty_environments: null,
        backup_for_critical_machinery: null,
        use_of_biting_machines: null,
        equipment_or_material_with_long_replacement_time: null,
      },
      security_and_logistics: {
        contains_easily_resellable_goods: null,
        contains_hard_to_identify_goods: null,
        frequent_access_by_non_staff: null,
        contains_easily_transportable_goods: null,
        reported_occupational_illnesses: null,
        third_party_access_for_installations_or_services: null,
        provides_installations_or_services_at_third_party_locations: null,
        stores_third_party_goods: null,
        high_value_domestic_transported_goods: null,
        high_value_international_transported_goods: null,
        ships_perishable_goods: null,
        subject_to_hazardous_material_transport_regulations: null,
        uses_contract_logistics_providers: null,
      },
      product_and_its: {
        company_branding_on_products: null,
        imports_into_eu: null,
        sells_in_us_canada_mexico: null,
        perishable_products: null,
        products_use_electricity: null,
        products_use_combustible_gases: null,
        provides_installation_and_maintenance: null,
        presence_of_special_goods: null,
        process_bottlenecks: null,
        vulnerability_to_wetting: '',
        easy_to_move_goods_away_from_wet: null,
        high_workspace_utilization: '',
        operates_in_large_distribution_chain: null,
        adequately_stocked_spare_parts_warehouse: null,
        critical_business_documents: null,
        possibility_of_increasing_work_shifts: null,
        disaster_recovery_and_business_continuity_system: null,
        remote_access_to_information_system: null,
        backup_communication_system: null,
        adopted_organizational_models_for_crisis_management: null,
      },
      finance_and_personals: {
        credit_system_based_on_risk: null,
        payment_methods_minimize_exposure: null,
        bank_credit_utilization_above_60_percent: null,
        seasonal_or_periodic_revenue_fluctuations: null,
        inventory_volatility_above_20_percent: null,
        subsidiaries_in_eu_or_switzerland: null,
        key_personnel_for_strategy: null,
        key_personnel_for_production: null,
        key_personnel_with_exclusive_client_relationships: null,
        locations_or_employees_outside_eu_norway_switzerland: null,
        executives_own_company_shares: null,
        conflict_of_interest_with_consulting_firms: null,
        sponsorship_contracts: null,
        defined_organizational_chart_and_governance: null,
        public_exposure_of_brand: null,
      },
    },
  });

  const submitData = (answers) => {
    answers.extra_data = extraData;
    saveQuestionario(company_id, answers, navigate);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const submitSkipQuestionario = async (val) => {
    if (extraData.extra_quotations === null) {
      return;
    }

    if (extraData.extra_quotations === 1 && (extraData.company_branch_id === null || extraData.company_branch_id === '')) {
      generalAlertErrorToast('Seleziona una sede');
      return;
    }

    confirmAlert('Sei sicuro di voler firmare più tardi?', async () => {
      await skipQuestionario(company_id, val, old_request_token, extraData);
      setSkip(val == 1);
    }, null, null, '#5cb85c');
  };

  // Reset request token when component unmounts
  useEffect(() => () => {
    resetQuestionnaireRequestToken();
    resetQuestionnaireModifyData();
  }, []);

  useEffect(() => {
    if (request_token !== null && request_token !== undefined) {
      navigate(`/quotes/commercial/${request_token}`, { state: { errorMessage } });
    }
  }, [request_token]);

  const errorMessages = (inputName) => (get(errors, inputName) ? get(errors, `${inputName}.message`) : null);

  useEffect(() => {
    if (modify_data && modify_data !== null && modify_data !== undefined && Object.keys(modify_data).length > 0) {
      const keys_to_ignore = ['id', 'commercial_questionario_id', 'created_at', 'updated_at'];
      // set location and structures values from modify data
      Object.keys(modify_data.location_and_structures).map((key) => {
        if (modify_data.location_and_structures[key] !== null && !keys_to_ignore.includes(key)) {
          setValue(`location_and_structures.${key}`, modify_data.location_and_structures[key].toString());
        }
        return null;
      });

      // set infrastructure and machines values from modify data

      Object.keys(modify_data.infrastructure_and_machines).map((key) => {
        if (modify_data.infrastructure_and_machines[key] !== null && !keys_to_ignore.includes(key)) {
          setValue(`infrastructure_and_machines.${key}`, modify_data.infrastructure_and_machines[key].toString());
        }
        return null;
      });

      // set security and logistics values from modify data
      Object.keys(modify_data.security_and_logistics).map((key) => {
        if (modify_data.security_and_logistics[key] !== null && !keys_to_ignore.includes(key)) {
          setValue(`security_and_logistics.${key}`, modify_data.security_and_logistics[key].toString());
        }
        return null;
      });

      // set product and its values from modify data
      Object.keys(modify_data.product_and_its).map((key) => {
        if (modify_data.product_and_its[key] !== null && !keys_to_ignore.includes(key)) {
          setValue(`product_and_its.${key}`, modify_data.product_and_its[key].toString());
        }
        return null;
      });

      // set finance and personals values from modify data
      Object.keys(modify_data.finance_and_personals).map((key) => {
        if (modify_data.finance_and_personals[key] !== null && !keys_to_ignore.includes(key)) {
          setValue(`finance_and_personals.${key}`, modify_data.finance_and_personals[key].toString());
        }
        return null;
      });
    }
  }, [modify_data]);

  useEffect(() => {
    if (company_id) {
      getCommercialBranches(company_id);
    }
  }, [company_id]);

  return (
    <CommercialQuestionnaireStyles>
      <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
        <div className="row title-questionnaire">
          <div className="quote-h2 divider">
            <h2>Questionario</h2>
          </div>
        </div>
      </CenteredDivTitle>
      {generatingQuotes === true && <CommercialQuoteLoading />}
      {skip === false && (
        <div className="container">
          <SkipQuestionarioStyles className="card mx-auto">
            <div className="card-body p-0">
              <SkipQuestionario>
                <FormInputRadio
                  label="Desideri aggiungere una quotazione sui rischi collegati al fabbricato?"
                  options={flagResponse}
                  onChange={(val) => setExtraData({ ...extraData, extra_quotations: val })}
                  value={extraData.extra_quotations}
                />
                {extraData.extra_quotations !== null && (
                  <>
                    {extraData.extra_quotations == 1 && company_branches && company_branches.length > 0 && (
                      <FormInputSelect
                        label="Sedi"
                        placeholder="Seleziona una sede"
                        options={company_branches}
                        onChange={(val) => setExtraData({ ...extraData, company_branch_id: val })}
                        value={extraData.company_branch_id}
                      />
                    )}
                    <p className="text-center">Vuoi continuare con il questionario?</p>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                      <button className="btn btn-success border py-1 px-4 px-md-5" onClick={() => submitSkipQuestionario(1)}>
                        Sì
                      </button>
                      <button className="btn btn-danger border py-1 px-4 px-md-5" onClick={() => submitSkipQuestionario(0)}>
                        No
                      </button>
                    </div>
                  </>
                )}
              </SkipQuestionario>
            </div>
          </SkipQuestionarioStyles>
        </div>
      )}

      {skip === true
        && (
          <form onSubmit={handleSubmit(submitData)}>
            <div className="container">
              <div className="accordion" id="accordionExample">
                <LocationAndAmbient errorMessages={errorMessages} register={register} errors={errors} />
                <StructureEdilizia errorMessages={errorMessages} register={register} errors={errors} />
                <InfrastructureImpianti errorMessages={errorMessages} register={register} errors={errors} />
                <MacchinariEAttrezature errorMessages={errorMessages} register={register} errors={errors} />
                <SicurezzaEPrevenzione errorMessages={errorMessages} register={register} errors={errors} />
                <LogisticaTrasporti errorMessages={errorMessages} register={register} errors={errors} />
                <ProcessProdotti errorMessages={errorMessages} register={register} errors={errors} />
                <ContinuitaIt errorMessages={errorMessages} register={register} errors={errors} />
                <FinanzaCrediti errorMessages={errorMessages} register={register} errors={errors} />
                <PersonaleRuoliChiave errorMessages={errorMessages} register={register} errors={errors} />
              </div>
            </div>
            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button className="btn btn-questionnaire" type="submit">
                  Continua
                </button>

              </div>
            </div>
          </form>
        )}
    </CommercialQuestionnaireStyles>
  );
}

export default CommercialQuestionnaire;
