import styled from 'styled-components';

export const WaitingForPaymentInfo = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 56px */
  }
`;

export const PayByCardContainer = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 30px;
  }

  .field-row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border-bottom: 1px solid grey;
  }

  .field-row:last-child {
    border-bottom: none;
  }

  .field-name {
    flex: 1;
  }

  .invoice-totals .field-name {
    flex: 1;
    min-width: unset;
  }

  .field-value {
    flex: 1;
  }

  @media (max-width: 600px) {
    .field-row {
      flex-direction: column;
    }
  }

  .field-value.pull-right {
    text-align: right;
  }

  .payment-record {
    margin-bottom: 10px;
    padding: 10px;
    max-width: 600px;
    border: 1px solid grey;
  }
`;

export const PayByBankTransfer = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;

export const WaitingForPaymentQuoteInfo = styled.div`
  border-radius: 30px;
  padding: 25px 5px;

  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 56px */
  }
`;

export const InfoBoxContainer = styled.div`
  margin-top: 30px;
  width: 80%;
  padding-bottom: 30px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 15px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  h4 {
    color: #00a651;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-align: start;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-align: end;
  }

  @media only screen and (max-width: 900px) {
    h4 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const LogoPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 900px) {
    gap: 10px;
  }
`;
export const LogoContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 80px;
  }

  @media only screen and (max-width: 900px) {
    width: 90px;
    height: 90px;
    img {
      max-width: 60px;
    }
  }
`;
export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const Price = styled.div`
  h5 {
    margin-bottom: -5px;
    color: #00a551;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  h6 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: -10px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 45%;
      left: -2px;
      top: calc(50% - 2px);
      background-color: #343434;
      z-index: 9999;
    }
  }

  h2 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    h5 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const QuoteInfoContainer = styled.div``;

export const QuoteInformationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;

  @media only screen and (max-width: 900px) {
    gap: 15px;
    margin-top: 15px;
  }
`;

export const QuoteInformationItem = styled.div`
  h6 {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const QuoteAmountInfo = styled.div`
  margin-top: 20px;
`;

export const QuoteAmountList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;

  h3 {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }

  h4 {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }

  h2 {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 10px;
  }

  h5 {
    color: #00a651;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 10px;
  }
`;

export const BankTransferInfo = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const BankTransferInfoItem = styled.li`
  color: #00a651;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    color: #343434;
    margin-left: 10px;
    font-weight: 500;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
  }
`;

export const FormInputGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  label {
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  input {
    border-radius: 15px;
    padding: 8px 15px;
    border: 2px solid
      ${(props) => (props.isInvalid === true ? 'rgba(240, 0, 0, 0.4)' : '#dbdde6')};
    background: #fff;
    width: 80%;

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }

    ::placeholder {
      color: #dbdde6;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  @media (max-width: 1200px) {
    label {
      font-size: 12px;
    }
  }
`;

export const FormInputGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  input {
    width: 90%;

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }
  }
`;

export const WarningInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 10px;
  margin-top: 10px;

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 100% */
  }

  img {
    max-width: 25px;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    p {
      font-size: 10px;
    }
    gap: 15px;
    align-items: flex-start;
  }
`;

export const PayNowButton = styled.button`
  margin-top: 20px;
  border-radius: 15px;
  background: #99dbb9;
  color: #343434;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 2px solid #99dbb9;
  padding: 15px 30px;
  transition: all 250ms linear;

  &:hover {
    background: transparent;
  }

  @media (max-width: 1200px) {
    padding: 10px 20px;
    border-radius: 10px;
  }
`;

export const ErrorMessage = styled.p`
  color: rgba(240, 0, 0, 0.4);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-left: 5px;
`;

export const PaymentProofStyled = styled.div`
  margin: 20px auto;
`;

export const MarkupContainer = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
`;

export const MarkupTitle = styled.h2`
  color: #343434;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;

  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`;

export const DefaultInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 15px;

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 100% */
  }

  img {
    max-width: 18px;
  }

  @media only screen and (max-width: 600px) {
    p {
      font-size: 10px;
    }
    gap: 15px;
    align-items: flex-start;
  }
`;

export const AddGuaranteesButton = styled.button`
  border-radius: 15px;
  background: #dff3ea;
  border: 1px solid #dff3ea;
  padding: 12px 40px;
  color: #00a651;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: auto;
  display: table;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #00a651;
    color: #00a651;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
    width: 100%;
  }
`;

export const RemoveGuaranteeButton = styled.button`
  border-radius: 15px;
  background: #FFB9B9;
  border: 1px solid #FFB9B9;
  padding: 12px 44px;
  color: #FF0000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: table;
  transition: all 300ms ease-in-out;

  &:hover {
    background: transparent;
    border: 1px solid #FF0000;
    color: #FF0000;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
    width: 100%;
  }
`;

export const MarkupSliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  gap: 20px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const MarkupPrice = styled.div`
  border-radius: 10px;
  min-width: 90px;
  text-align: center;
  padding: 10px;
  background: #dff3ea;
  color: #343434;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const MarkupSlider = styled.div`
  width: 100%;

  @media screen and (max-width: 900px) {
    .range-responsive-circle {
      width: 30px !important;
      height: 30px !important;
    }

    .range-responsive-slide {
      height: 20px !important;
    }

    .range-responsive-text {
      font-size: 16px !important;
    }
  }
`;

export const QuoteAmountTable = styled.div`
 .font-smaller{
  font-size: 16px;
 }


.table {
  border-collapse: separate; /* Use separate to make border-radius work */
  border-spacing: 0; 
  border-radius: 15px; 
  overflow: hidden; 
}


 .table thead th {
  background-color: #4CAF50;
  color: white;
  padding: 6px; /* Reduced padding */
  text-align: left;
  border-bottom: 2px solid #ddd;
}

table tbody td , table tbody th{
  padding: 6px; /* Reduced padding */
}
`;

export const FormInputGroupBrokeragio = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;

  label {
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  input {
    border-radius: 15px;
    padding: 8px 15px;
    border: 2px solid
      ${(props) => (props.isInvalid === true ? 'rgba(240, 0, 0, 0.4)' : '#dbdde6')};
    background: #fff;
    width: 30%;

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }

    ::placeholder {
      color: #dbdde6;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  @media (max-width: 1200px) {
    margin-top: 40px;
    input {
      width: 100%;

    }
  }

  @media (max-width: 1200px) {
    label {
      font-size: 13px;
    }
  }
`;
